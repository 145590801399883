import React, { useContext, useEffect, useState } from 'react';
import type { ComponentType } from 'react';

import observer from 'saddlebag-observer';

import type { UserContext as UserContextType } from 'common/src/types/user-context';

import { getUserPreferences } from '../services/user-preferences';

import displayName from './display-name';

interface ContextProps {
  userContext: UserContextType;
}

// @ts-expect-error missing properties
const UserContext = React.createContext<UserContext>({});

const withUserContext = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const WithUserContext: React.FC<Omit<P, 'userContext'>> = (props) => {
    const userContext = useContext(UserContext);
    const [isLoggedIn, setIsLoggedIn] = useState(
      userContext.userPreferences.isLoggedIn,
    );

    useEffect(() => {
      const onLoginSuccessHandler = observer.subscribe(
        'sid-login-success',
        () => {
          setIsLoggedIn(true);
        },
      );

      return () => {
        observer.unsubscribe(onLoginSuccessHandler);
      };
    }, []);

    return (
      <Component
        {...(props as P)}
        userContext={{
          ...(userContext as Omit<UserContextType, 'userPreferences'>),
          userPreferences: {
            ...userContext.userPreferences,
            ...getUserPreferences(isLoggedIn),
          },
        }}
      />
    );
  };

  WithUserContext.displayName = displayName(Component);

  return WithUserContext;
};

export { withUserContext };
export default UserContext;
