import type { Device } from 'common/src/types/user-context';

import objValues from '../../utils/object/obj-values';
import getDeviceType from '../../components/get-device-type';
import { ACTION_TYPE, DEVICE } from '../minievents/constants';
import {
  HOTELS_FRONTEND_SCHEMA_NAME,
  HOTELS_FRONTEND_MINIEVENT_NAME,
} from '../minievents/hotels-action';
import {
  EXPERIMENT_CHOKE_POINT_SCHEMA_NAME,
  EXPERIMENT_CHOKE_POINT_MINIEVENT_NAME,
} from '../minievents/choke-point';
import {
  HOTELS_SEEN_SCHEMA_NAME,
  HOTELS_SEEN_MINIEVENT_NAME,
} from '../minievents/hotels-seen';
import {
  AUTOSUGGEST_TELEMETRY_SCHEMA_NAME,
  AUTOSUGGEST_MINIEVENT_NAME,
} from '../minievents/autosuggest';
import {
  EXPERIMENT_SMART_METRIC_MINIEVENT_NAME,
  EXPERIMENT_SMART_METRIC_SCHEMA_NAME,
} from '../minievents/smart-event';

import type {
  EventProps,
  OneOfMessage,
  ObserverClient,
  ElementEventTracker as ElementEventTrackerType,
} from '../types';

const GRAPPLER_EVENT = 'grappler-track';

type Props = {
  impressionId: string;
  device?: Device;
  isWebView: boolean;
  trafficSource: string;
  utmSource: string | null;
  observerClient: ObserverClient;
  pageType: string;
};

const ElementEventTracker = ({
  device,
  impressionId,
  isWebView = false,
  observerClient,
  pageType,
  trafficSource,
  utmSource,
}: Props): ElementEventTrackerType => {
  const miniEventActions = objValues(ACTION_TYPE);

  const trackMiniEvent = ({
    consentRequired = true,
    eventName,
    fullSchemaName,
    message,
  }: EventProps) => {
    observerClient.track(GRAPPLER_EVENT, {
      eventName,
      fullSchemaName,
      message,
      consentRequired,
      isMiniEvent: true,
    });
  };

  const trackHotelsAction = (name: string, props: OneOfMessage = {}) => {
    if (!miniEventActions.includes(name)) {
      throw new Error(
        'Actions on Element Events should be from the ACTION_TYPE object',
      );
    }

    trackMiniEvent({
      eventName: HOTELS_FRONTEND_MINIEVENT_NAME,
      fullSchemaName: HOTELS_FRONTEND_SCHEMA_NAME,
      message: {
        action_type: name,
        is_bot: device ? device.isRobot : true,
        traffic_source: trafficSource,
        utm_source: utmSource,
        device: DEVICE[getDeviceType(device, isWebView).toUpperCase()],
        impression_id: impressionId,
        ...props,
      },
    });
  };

  const trackExperimentChokepoint = (experimentName: string) => {
    trackMiniEvent({
      eventName: EXPERIMENT_CHOKE_POINT_MINIEVENT_NAME,
      fullSchemaName: EXPERIMENT_CHOKE_POINT_SCHEMA_NAME,
      message: {
        name: experimentName,
      },
    });
  };

  const trackSmartMetric = (smartMetricName: string) => {
    trackMiniEvent({
      eventName: EXPERIMENT_SMART_METRIC_MINIEVENT_NAME,
      fullSchemaName: EXPERIMENT_SMART_METRIC_SCHEMA_NAME,
      message: {
        name: smartMetricName,
      },
    });
  };

  const trackHotelsSeen = (props: OneOfMessage = {}) => {
    trackMiniEvent({
      eventName: HOTELS_SEEN_MINIEVENT_NAME,
      fullSchemaName: HOTELS_SEEN_SCHEMA_NAME,
      message: {
        traffic_source: trafficSource,
        impression_id: impressionId,
        device: DEVICE[getDeviceType(device, isWebView).toUpperCase()],
        ...props,
      },
    });
  };

  const trackAutoSuggest = (props: OneOfMessage = {}) => {
    trackMiniEvent({
      eventName: AUTOSUGGEST_MINIEVENT_NAME,
      fullSchemaName: AUTOSUGGEST_TELEMETRY_SCHEMA_NAME,
      message: {
        platform: DEVICE[getDeviceType(device, isWebView).toUpperCase()],
        page_type: pageType,
        ...props,
      },
    });
  };

  return {
    trackMiniEvent,

    trackHotelsAction,

    trackExperimentChokepoint,

    trackSmartMetric,

    trackHotelsSeen,

    trackAutoSuggest,
  };
};

export default ElementEventTracker;
