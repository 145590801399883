import PageTypes from '../pageTypes';

type Source = string | null | undefined;

const XSELL_MAP = 'XSellMap';
const HPA_REG = /^[A-Z]\w+Hpa$/;
const HOTEL_FEED_REG = /^[a-zA-Z]+Display$/;
const FLIGHT_PQS = 'FLIGHT_PQS';
const FLIGHT_DBOOK_COUPON = 'FlightDBookCoupon';
const FLIGHT_DBOOK_EMAIL_LOW = 'FlightDBookEmailLow';
const FLIGHT_DBOOK_EMAIL_MIDDLE = 'FlightDBookEmailMiddle';
const FLIGHT_DAYVIEW_ADS = 'FlightsDayviewAds';
const FLIGHT_DAIVIEW_OC = 'FlightsDayViewOC';
const LIFE_CYCLE_MARKET_DIRECT_DISCOUNT_EMAIL = 'LCMDirectDiscountEmail';
const HOME_ADS = 'HomeAds';
const HOME_ADS_APP = 'HomeAdsApp';
const NEW_RELIC = 'NewRelic';
const HOTELS_DEALS = 'hotels-deals';
const HOME_ADS_APP_NAV_CARD = 'HomeAdsAppNavCard';
const HOME_ADS_NAV_CARD = 'HomeAdsNavCard';
const COMBINED_EXPLORE_IDENTITY = 'CombinedExplore';
const HOTELS_BOTTOM_XSELL_DEALS = 'hotelsBottomXSellDeals';
const SEO_TRAFFIC_SOURCE = 'SEO';

const DIRECT_DISCOUNT_PAGES = [
  'PAGE_TYPE_UNSET',
  'DAYVIEW',
  'HOTEL_DETAIL',
  'FLIGHT_DAYVIEW',
  'HOTEL_DAYVIEW',
  'FLIGHT_SUCCESSFUL_END_PAGE',
  'FLIGHT_CHECKOUT',
];

const HOTELS_INLINE_XSELL = ['hotelsInlineXSell', 'hotelsInlineXSellDeals'];

const DEALS_PAGE_SOURCE = [
  HOME_ADS,
  HOME_ADS_APP,
  FLIGHT_DAYVIEW_ADS,
  HOME_ADS_APP_NAV_CARD,
  HOME_ADS_NAV_CARD,
];

const GREAT_PRICE_EXCLUDE_SOURCE = [
  FLIGHT_DAYVIEW_ADS,
  HOTELS_DEALS,
  ...HOTELS_INLINE_XSELL,
];

const DIRECT_DISCOUNT_SOURCE = [...DIRECT_DISCOUNT_PAGES, FLIGHT_PQS];

const EMAIL_COUPON_SOURCE = [FLIGHT_DBOOK_EMAIL_LOW, FLIGHT_DBOOK_EMAIL_MIDDLE];

const FLIGHT_OTHER_SOURCE = [
  FLIGHT_DBOOK_COUPON,
  XSELL_MAP,
  FLIGHT_DAYVIEW_ADS,
  FLIGHT_DAIVIEW_OC,
];

const EXCLUDE_HIDE_CUG_SOURCE = [
  ...HOTELS_INLINE_XSELL,
  HOTELS_BOTTOM_XSELL_DEALS,
  FLIGHT_PQS,
];

const isFromFlightDirectDiscount = (source: Source) =>
  !!(source && DIRECT_DISCOUNT_SOURCE.includes(source));

const isFromDirectDiscountPage = (source: Source) =>
  !!(source && DIRECT_DISCOUNT_PAGES.includes(source));

const isFromFlightDBookEmail = (source: Source) =>
  !!(source && EMAIL_COUPON_SOURCE.includes(source));

const isFromLifeCycleMarketDirectDiscountEmail = (source: Source) =>
  source === LIFE_CYCLE_MARKET_DIRECT_DISCOUNT_EMAIL;

const isFromFlightsDayviewAds = (source: Source) =>
  source === FLIGHT_DAYVIEW_ADS;

const isFromHPA = (source: Source) => !!(source && HPA_REG.test(source));

const isFromHotelFeed = (source: Source) =>
  !!(source && HOTEL_FEED_REG.test(source));

const isExcludeGreatPrice = (source: Source) =>
  !!(source && GREAT_PRICE_EXCLUDE_SOURCE.includes(source)) ||
  isFromHPA(source);

const isFromCombinedExplore = (source: Source) =>
  !!(source && source.includes(COMBINED_EXPLORE_IDENTITY));

const isExcludeHideCug = (source: Source) =>
  !!(source && EXCLUDE_HIDE_CUG_SOURCE.includes(source));

const isFromSeoTraffic = (source: Source) => source === SEO_TRAFFIC_SOURCE;

const trafficSource = ({ source }: { source: Source }): string => {
  if (
    isFromFlightDBookEmail(source) ||
    isFromFlightDirectDiscount(source) ||
    isFromFlightsDayviewAds(source) ||
    isFromLifeCycleMarketDirectDiscountEmail(source) ||
    isFromHPA(source) ||
    isFromHotelFeed(source) ||
    (source && FLIGHT_OTHER_SOURCE.includes(source)) ||
    (source && DEALS_PAGE_SOURCE.includes(source)) ||
    (source && HOTELS_INLINE_XSELL.includes(source)) ||
    source === NEW_RELIC ||
    isFromCombinedExplore(source) ||
    isFromSeoTraffic(source)
  ) {
    return source as string;
  }

  if (source && Object.values(PageTypes).includes(source)) {
    return source
      .split('-')
      .reduce(
        (acc: string[], curr) => [
          ...acc,
          curr.charAt(0).toUpperCase(),
          curr.slice(1),
        ],
        [],
      )
      .join('');
  }

  return 'Unknown';
};

const getUtmSource = (utmSource: string) => {
  const regExp = /^([a-z-A-Z_]+)$/;
  if (utmSource && regExp.test(utmSource)) {
    return utmSource;
  }
  return null;
};

export {
  XSELL_MAP,
  FLIGHT_PQS,
  FLIGHT_DBOOK_COUPON,
  FLIGHT_DBOOK_EMAIL_LOW,
  FLIGHT_DBOOK_EMAIL_MIDDLE,
  SEO_TRAFFIC_SOURCE,
  isFromFlightDirectDiscount,
  isFromFlightDBookEmail,
  isFromFlightsDayviewAds,
  trafficSource,
  isFromDirectDiscountPage,
  isFromLifeCycleMarketDirectDiscountEmail,
  isFromHPA,
  isFromHotelFeed,
  isExcludeGreatPrice,
  isExcludeHideCug,
  getUtmSource,
  isFromSeoTraffic,
};
