import React from 'react';
import type { ComponentType } from 'react';

import type { Configs } from 'common/src/types/configs';

import displayName from '../../components/display-name';

const DEFAULT_CONFIGS = {};

interface ContextProps {
  configs: Configs;
}

const ConfigurationContext =
  // @ts-expect-error missing properties
  React.createContext<Configs>(DEFAULT_CONFIGS);

export const withConfiguration = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const Product = (props: Omit<P, 'configs'>) => (
    <ConfigurationContext.Consumer>
      {(configs) => (
        // @ts-expect-error we expect that configs can be overwritten in the unit tests
        <Component configs={configs} {...(props as P)} />
      )}
    </ConfigurationContext.Consumer>
  );

  Product.displayName = displayName(Component);

  return Product;
};

export default ConfigurationContext;
