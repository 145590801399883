import isPast from 'date-fns/isPast';

import {
  parseDate,
  differenceDaysLength,
} from '../../utils/machine-date-utils';

import stayLength from './stay-length';

import type { DateTime } from '../../types/utils';

const MAX_STAY_LENGTH = 30;
const MAX_DAYS_IN_ADVANCE = 564;

const isStayLengthValid = (
  checkIn: DateTime,
  checkOut: DateTime,
  maxStayLength = MAX_STAY_LENGTH,
) => {
  const length = stayLength({ checkIn, checkOut });
  return length >= 1 && length <= maxStayLength;
};

const isPastBeyondYesterday = (date: DateTime) =>
  isPast(parseDate(date)) &&
  // @ts-expect-error TS2345: Argument of type 'number' is not assignable to parameter of type 'DateTime'.
  differenceDaysLength(parseDate(date), Date.now()) < -1;

const isPastBeyondToday = (date: DateTime) =>
  isPast(parseDate(date)) &&
  // @ts-expect-error TS2345: Argument of type 'number' is not assignable to parameter of type 'DateTime'.
  differenceDaysLength(parseDate(date), Date.now()) < 0;

const areStayDatesValid = ({
  checkIn,
  checkOut,
}: { checkIn?: DateTime | null; checkOut?: DateTime | null } = {}) => {
  if (!checkIn || !checkOut) {
    return false;
  }

  if (isPastBeyondYesterday(checkIn) || isPastBeyondToday(checkOut)) {
    return false;
  }

  if (!isStayLengthValid(checkIn, checkOut)) {
    return false;
  }

  if (
    // @ts-expect-error TS2345: Argument of type 'number' is not assignable to parameter of type 'DateTime'.
    differenceDaysLength(parseDate(checkIn), Date.now()) > MAX_DAYS_IN_ADVANCE
  ) {
    return false;
  }

  return true;
};

export {
  MAX_STAY_LENGTH,
  MAX_DAYS_IN_ADVANCE,
  areStayDatesValid,
  isStayLengthValid,
  isPastBeyondToday,
};
