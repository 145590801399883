import { SingleValueCookie } from 'saddlebag-cookie';

import type { Nullable, ObjectMapping } from 'common/src/types/utils';

const X_GATEWAY_SERVED_BY = 'X-Gateway-Servedby';

const MAPPING_REGIONS: ObjectMapping = {
  'eu-west-1': 'gw51.skyscanner.net',
  'eu-central-1': 'gw53.skyscanner.net',
  'ap-northeast-1': 'gw54.skyscanner.net',
  'ap-southeast-1': 'gw52.skyscanner.net',
};

const storeXGatewayServedBy = (xGatewayServedBy: Nullable<string>) => {
  if (!xGatewayServedBy || xGatewayServedBy === 'undefined') {
    return;
  }
  const cookie = new SingleValueCookie(X_GATEWAY_SERVED_BY);
  cookie.setValue(xGatewayServedBy);
  cookie.store(1, { path: '/' });
};

const getXGatewayServedBy = () => {
  const cookie = new SingleValueCookie(X_GATEWAY_SERVED_BY);
  return cookie.getValue();
};

export {
  X_GATEWAY_SERVED_BY,
  MAPPING_REGIONS,
  getXGatewayServedBy,
  storeXGatewayServedBy,
};
