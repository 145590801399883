import { differenceDaysLength } from '../../utils/machine-date-utils';

import type { DateTime } from '../../types/utils';

const stayLength = ({
  checkIn,
  checkOut,
}: {
  checkIn: DateTime;
  checkOut: DateTime;
}) => differenceDaysLength(checkOut, checkIn);

export default stayLength;
