import React from 'react';
import type { ComponentType } from 'react';

import displayName from '../../components/display-name';

import type { ElementEventTracker } from '../types';

interface ContextProps {
  elementEventTracker: ElementEventTracker;
}

const ElementEventTrackerContext = React.createContext<ElementEventTracker>(
  // @ts-expect-error missing properties
  {},
);

const withElementEventTracker = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const component = (props: Omit<P, 'elementEventTracker'>) => (
    <ElementEventTrackerContext.Consumer>
      {(elementEventTracker: ElementEventTracker) => (
        <Component
          // @ts-expect-error we expect that elementEventTracker can be overwritten in the unit tests
          elementEventTracker={elementEventTracker}
          {...(props as P)}
        />
      )}
    </ElementEventTrackerContext.Consumer>
  );

  component.displayName = displayName(Component);

  return component;
};

export default ElementEventTrackerContext;
export { withElementEventTracker };
