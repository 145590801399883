import { getUtid } from 'saddlebag-user-management';

import type { UserPreferences } from 'common/src/types/user-context';

const getUserPreferences = (isLoggedIn: boolean): UserPreferences => {
  const utid: string = getUtid();

  return {
    isLoggedIn,
    utid,
    userPreferencesId: utid,
  };
};

export { getUserPreferences };
