import React from 'react';
import type { ReactElement } from 'react';

import type { Nullable } from 'common/src/types/utils';

import { withLogging } from '../../components/LoggingContext';
import { withMetrics } from '../application-metrics';
import updatedErrorStack from '../../utils/update-error-stack';

import type { Metrics } from '../types';
import type { Logger } from '../../types/logger';

type Props = {
  errorPage: Nullable<string>;
  logger: Logger;
  metrics: Metrics;
  children: ReactElement;
};

class AppErrorBoundary extends React.Component<Props> {
  componentDidCatch(
    error: Error,
    { componentStack }: { componentStack: string },
  ) {
    const { errorPage, logger, metrics } = this.props;
    const { error: updatedError, stackData } = updatedErrorStack(
      error,
      componentStack,
    );
    logger.error('Caught error in ErrorBoundary', stackData, updatedError);
    metrics.pageLoadFailure();
    if (errorPage) {
      // There is a special /500.ashx endpoint for tracking a client side crash
      // https://tower.skyscannertools.net/systems/component/pokemon/overview
      window.location.replace(errorPage);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withMetrics(withLogging(AppErrorBoundary));
