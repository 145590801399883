import urlParse from 'url-parse';
import { GLOBAL } from 'saddlebag-browser';

import { formatDate } from 'common/src/utils/machine-date-utils';
import { getQueryParam, getIntParam } from 'common/src/services/url/url';
import type {
  Maybe,
  Stay as StayShape,
  Nullable,
} from 'common/src/types/utils';
import type { SearchFilters } from 'common/src/types/url-params';

import { ROUTES } from '../routes';

import type { Destination } from '../../components/types';
import type { Stay } from '../../types/hotels-deals-response';

export const getCurrentQueryParam = (parameter: string) => {
  const $window = GLOBAL.getWindow();
  if ($window && $window.location && $window.location.href) {
    return getQueryParam($window.location.href, parameter) || null;
  }
  return null;
};

export const getCurrentIntParam = (name: string) =>
  getIntParam(window.location.href, name);

const pushNewUrl = (url: string) => {
  window.history.pushState(
    {}, // State
    '', // Title, currently not in spec so not used by browsers
    url,
  );
};

export const removeHashRoutesFromUrl = (url: string): string =>
  ROUTES.reduce((acc: string, curr: string) => acc.replace(curr, ''), url);

type SearchParamsBase = {
  stay?: StayShape | Stay;
  filters?: Nullable<SearchFilters>;
  destination?: Destination;
  freeCancellation?: Maybe<boolean>;
  impressionId?: string;
};

type SearchParams = SearchParamsBase & {
  url: string;
  clickedDetailsPrice?: number;
  minPriceRoomId?: Nullable<string>;
  poiId?: number;
  searchCycleId?: Nullable<string>;
  sort?: string;
  upsortHotels?: number;
  traceInfo?: string;
  extraTraceInfo?: string;
  source?: Nullable<string>;
};

export const getUpdatedSearchParamsUrl = ({
  clickedDetailsPrice,
  destination,
  extraTraceInfo,
  filters,
  freeCancellation,
  impressionId,
  minPriceRoomId,
  poiId,
  searchCycleId,
  sort,
  source,
  stay,
  traceInfo,
  upsortHotels,
  url,
}: SearchParams): string => {
  const { entityId } = destination || {};
  // @ts-expect-error type Stay have no type childrenAges, may be it was difference from two service page define
  const { checkIn, checkOut, childrenAges, numberOfAdults, numberOfRooms } =
    stay || {};
  const urlObj = urlParse(removeHashRoutesFromUrl(url), true);
  const { query } = urlObj;
  const childrenAgesString = childrenAges && childrenAges.join(',');

  if (entityId) {
    query.entity_id = entityId;
    delete query['s-f_iplace'];
  }
  if (checkIn) {
    query.checkin = formatDate(checkIn);
  }
  if (checkOut) {
    query.checkout = formatDate(checkOut);
  }
  if (numberOfAdults) {
    query.adults = numberOfAdults;
  }
  if (numberOfRooms) {
    query.rooms = numberOfRooms;
  }
  if (childrenAgesString) {
    query.children_ages = childrenAgesString;
  } else {
    delete query.children_ages;
  }
  if (sort) {
    query.sort = sort;
  } else {
    delete query.sort;
  }
  if (searchCycleId) {
    query.search_cycle_id = searchCycleId;
  } else {
    delete query.search_cycle_id;
  }
  if (freeCancellation) {
    query.free_cancellation = freeCancellation;
  } else {
    delete query.free_cancellation;
  }
  if (filters && Object.keys(filters).length) {
    query.filters = JSON.stringify(filters);
  } else {
    delete query.filters;
  }
  if (upsortHotels) {
    query.upsort_hotels = upsortHotels;
  } else {
    delete query.upsort_hotels;
  }
  if (poiId) {
    query.poi_id = poiId;
  } else {
    delete query.poi_id;
  }
  if (impressionId) {
    query.impression_id = impressionId;
  }
  if (clickedDetailsPrice) {
    query.clicked_details_price = clickedDetailsPrice;
  } else {
    delete query.clicked_details_price;
  }
  if (minPriceRoomId) {
    query.min_price_room_id = minPriceRoomId;
  } else {
    delete query.min_price_room_id;
  }
  if (traceInfo) {
    query.trace_info = traceInfo;
  } else {
    delete query.trace_info;
  }
  if (extraTraceInfo) {
    query.extra_trace_info = extraTraceInfo;
  } else {
    delete query.extra_trace_info;
  }
  if (source) {
    query.source = source;
  } else {
    delete query.source;
  }

  urlObj.set('query', query);
  return urlObj.toString();
};

export const updateSearchParamsUrl = ({
  clickedDetailsPrice,
  destination,
  extraTraceInfo,
  filters,
  freeCancellation,
  minPriceRoomId,
  poiId,
  searchCycleId,
  sort,
  source,
  stay,
  traceInfo,
  upsortHotels,
}: Omit<SearchParams, 'url'>) => {
  const newUrl = getUpdatedSearchParamsUrl({
    url: window.location.href,
    destination,
    stay,
    sort,
    searchCycleId,
    freeCancellation,
    filters,
    upsortHotels,
    poiId,
    clickedDetailsPrice,
    minPriceRoomId,
    traceInfo,
    extraTraceInfo,
    source,
  });

  pushNewUrl(newUrl);
};

export const updateCheckcoutUrl = (url: string, isCheapest: boolean) =>
  typeof window !== 'undefined' && isCheapest ? `${url}&priceFlag=1` : url;

export const appendRedirectIdForUrl = ({
  deepLink,
  redirectId,
}: {
  deepLink: string;
  redirectId: string;
}) =>
  typeof window !== 'undefined'
    ? `${deepLink}&redirect_id=${redirectId}`
    : deepLink;

export const getCugOverrideFromUrl = (url: string) =>
  getQueryParam(url, 'cugOverride');

export const getDayviewUrl = ({
  destination,
  filters,
  freeCancellation,
  impressionId,
  stay,
}: SearchParamsBase) => {
  const baseUrl = `/hotels/search`;

  return getUpdatedSearchParamsUrl({
    url: baseUrl,
    destination,
    stay,
    freeCancellation,
    filters,
    impressionId,
  });
};
