import type { ComponentClass } from 'react';

import { loadableReady } from '@loadable/component';

import type { PageProps, ImportApp } from '../types';

type Params = {
  hydrateClient: boolean;
  props: PageProps;
  importApp: ImportApp;
};

const setupReactPage = async ({
  hydrateClient,
  importApp,
  props: appProps,
}: Params) => {
  const React = await import(/* webpackMode: "eager" */ 'react');
  const ReactDOM = await import(/* webpackMode: "eager" */ 'react-dom');

  const renderComponent = (
    component: ComponentClass,
    props: PageProps,
    container: HTMLElement | null,
  ) => {
    // Instantiate the React bundle and insert onto the page,
    // hydrate or render the bundle based on if in a development flow with hot
    // reloading or in production
    if (hydrateClient) {
      // @ts-expect-error TS2769: No overload matches this call. PageProps has no properties in common with type 'Attributes'.
      ReactDOM.hydrate(React.createElement(component, props), container);
    } else {
      // @ts-expect-error TS2769: No overload matches this call. PageProps has no properties in common with type 'Attributes'.
      ReactDOM.render(React.createElement(component, props), container);
    }
  };
  // Create our React Bundle
  // Global utility objects like i18n and metrics are created from data sent from the
  // server and accessed through window.__internal
  const reactApp = await importApp();
  loadableReady(() => {
    renderComponent(reactApp, appProps, document.getElementById('app-root'));
  });
};

export default setupReactPage;
