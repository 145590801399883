export default {
  EUR: '€',
  GBP: '£',
  USD: '$',
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'Lek',
  AMD: 'դր.',
  ANG: 'NAf.',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'Afl.',
  AZN: '₼',
  BAM: 'КМ',
  BBD: '$',
  BDT: 'BDT',
  BGN: 'лв.',
  BHD: 'د.ب.‏',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: 'Bs',
  BRL: 'R$',
  BSD: '$',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BZD: 'BZ$',
  CAD: 'C$',
  CDF: 'FC',
  CHF: 'CHF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUC: 'CUC',
  CUP: '$MN',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr.',
  DOP: 'RD$',
  DZD: 'د.ج.‏',
  EGP: 'ج.م.‏',
  ERN: 'Nfk',
  ETB: 'Br',
  FJD: '$',
  GEL: '₾',
  GHS: 'GH¢',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: 'HK$',
  HNL: 'L.',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  IQD: 'د.ع.‏',
  IRR: 'ريال',
  ISK: 'kr.',
  JMD: 'J$',
  JOD: 'د.ا.‏',
  JPY: '¥',
  KES: 'S',
  KGS: 'сом',
  KHR: 'KHR',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'د.ك.‏',
  KYD: '$',
  KZT: 'Т',
  LAK: '₭',
  LBP: 'ل.ل.‏',
  LKR: 'Rp',
  LRD: '$',
  LSL: 'M',
  LYD: 'د.ل.‏',
  MAD: 'د.م.‏',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден.',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MUR: 'Rs',
  MVR: 'MVR',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: 'रु',
  NZD: '$',
  OMR: 'ر.ع.‏',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: 'P',
  PKR: 'Rs',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: 'ر.ق.‏',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: '$',
  SCR: 'Rs',
  SDG: 'ج.س.‏',
  SEK: 'SEK',
  SGD: '$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  STD: 'Db',
  SYP: 'ل.س.‏',
  SZL: 'E',
  THB: '฿',
  TJS: 'TJS',
  TMT: 'm',
  TND: 'د.ت.‏',
  TOP: 'T$',
  TRY: 'TL',
  TTD: 'TT$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: 'грн.',
  UGX: 'USh',
  UYU: '$U',
  UZS: 'сўм',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'F',
  XCD: '$',
  XOF: 'F',
  XPF: 'F',
  YER: 'ر.ي.‏',
  ZAR: 'R',
  ZMW: 'ZK',
};
