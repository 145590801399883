import type { Environment } from 'common/src/types/utils';

import ObserverClient from '../observer-client';

import type { ObserverClient as ObserverClientType } from '../types';

type Props = {
  environment: Environment;
};

const buildObserverClient = ({ environment }: Props): ObserverClientType => {
  if (environment === 'development') {
    return ObserverClient({
      destinations: ['console'],
    });
  }

  if (environment === 'production') {
    return ObserverClient({ destinations: ['observer'] });
  }

  return ObserverClient({ destinations: ['console', 'event'] });
};

export default buildObserverClient;
