const isUrlAbsolute = (url: string) => url.startsWith('http');

export class StandardURL {
  href: string;

  constructor(
    givenUrl: string,
    { removeSearchPath = false, urlLib = null } = {},
  ) {
    this.href = '';

    let UrlClass = null;

    if (urlLib === null) UrlClass = URL;
    else UrlClass = urlLib;

    const newUrl = new UrlClass(givenUrl, 'https://skyscanner.net');

    if (removeSearchPath) newUrl.search = '';

    if (isUrlAbsolute(givenUrl)) {
      this.href = newUrl.href;
    } else {
      this.href = `${newUrl.pathname}${newUrl.search}`;
    }

    this.href = decodeURI(this.href);
  }
}
