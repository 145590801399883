import type { Amenities } from '../types/hotel-card';

const AMENITY_POSITIVE = [
  'free_cancellation',
  'refundable',
  'breakfast_included',
  'half_board',
  'full_board',
  'all_inclusive',
  'RoomRates_option_payOnArrival',
  'Filters_label_POA',
];

const getPositiveAmenities = (amenities: Amenities) =>
  amenities.filter((amenity) => AMENITY_POSITIVE.includes(amenity.name));

const getPositiveAmenitiesEvent = (amenities: Amenities) => {
  const positiveAmenities = getPositiveAmenities(amenities).reduce(
    (acc: string[], cur) => [...acc, cur.name as string],
    [],
  );

  return positiveAmenities.length > 0 ? positiveAmenities.join(',') : undefined;
};

export default getPositiveAmenities;

export { getPositiveAmenitiesEvent };
