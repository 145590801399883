import queryString from 'query-string';
import isValid from 'date-fns/isValid';

import { parseDate } from '../../utils/machine-date-utils';

export const getQueryParam = (url: string, name: string): string | null => {
  try {
    const { search } = new URL(url);
    const parsed = queryString.parse(search);
    const value = parsed[name];
    if (Array.isArray(value)) {
      return value[value.length - 1];
    }
    return value || null;
  } catch (e) {
    // if it is not a valid url, return null
    return null;
  }
};

export const getDateParam = (url: string, name: string) => {
  const val = getQueryParam(url, name);
  if (!val) {
    return null;
  }

  const date = parseDate(val);

  if (!isValid(date)) {
    return null;
  }

  return date;
};

export const getIntParam = (url: string, name: string) => {
  const val = getQueryParam(url, name);
  if (!val) {
    return null;
  }

  const number = parseInt(val, 10);
  return isNaN(number) ? null : number; // eslint-disable-line no-restricted-globals
};

export const getStringParam = (url: string, name: string) => {
  const val = getQueryParam(url, name);
  if (!val) {
    return null;
  }
  return val;
};
