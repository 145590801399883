import originalFetch from 'cross-fetch';

import { StandardURL } from 'common/src/services/url/StandardURL';
import type { HttpOptions } from 'common/src/types/utils';

import logger from './logger';

const fetchRetry = require('fetch-retry')(originalFetch);

type RetryType = {
  retries?: number;
  retryDelay?: Function | number;
  retryOn?: (
    attempt: number,
    error: Error,
    response: any,
  ) => boolean | number[];
};

const instrumentedFetch = async (
  url: string,
  opts: HttpOptions & RetryType = {},
) => {
  let status;
  const startTime = Date.now();

  // NOTE: Do NOT log the response body here because it might
  // contain PII which must never be logged.

  try {
    const response = await fetchRetry(url, { retries: 0, ...opts });
    ({ status } = response);
    return response;
  } catch (err) {
    status = 504;
    logger.error('Unknown exception on instrumented fetch', { url }, err);
    throw err;
  } finally {
    const duration = Date.now() - startTime;

    logger.event('remoteclients.calls', {
      url: new StandardURL(url, { removeSearchPath: true }).href,
      duration,
      status,
    });
  }
};

export default instrumentedFetch;
