import TranslationService from './translation-service';
import LocalisationService from './localisation-service';

import type { I18nServiceType } from '../../types/i18n';

const I18nService: I18nServiceType = (
  { cldr, culture, translations },
  logger,
) => {
  const translationService = TranslationService(translations);
  const localisationService = LocalisationService(cldr, culture, logger);
  return {
    ...translationService,
    ...localisationService,
    culture,
    translations: () => translations,
    cldr: () => cldr,
  };
};

export default I18nService;
