import React from 'react';
import type { ReactElement } from 'react';

import type { Configs } from 'common/src/types/configs';
import type { UserContext as UserContextType } from 'common/src/types/user-context';
import type {
  GoogleMaps,
  FeatureTestsMapping,
} from 'common/src/types/client-side-params';
import type { Nullable } from 'common/src/types/utils';
import type { I18nService } from 'common/src/types/i18n';

import MetricsContext from '../application-metrics/MetricsContext';
import LoggingContext from '../../components/LoggingContext';
import BackendContext from '../../components/backend-context';
import { ElementEventTrackerContext } from '../element-events';

import AppErrorBoundary from './AppErrorBoundary';
import StaticApplicationShell from './StaticApplicationShell';

import type { ElementEventTracker, Metrics } from '../types';
import type { Logger } from '../../types/logger';
import type { BackendGateway } from '../../types/backend-gateway';

type Props = {
  i18n: I18nService;
  children: ReactElement;
  configs: Configs;
  backendGateway: BackendGateway;
  elementEventTracker: ElementEventTracker;
  metrics: Metrics;
  errorPage: Nullable<string>;
  logger: Logger;
  userContext: UserContextType;
  featureTestsMapping: FeatureTestsMapping;
  googleMaps: GoogleMaps;
};

const BrowserApplicationShell = ({
  backendGateway,
  children,
  configs,
  elementEventTracker,
  errorPage,
  featureTestsMapping,
  googleMaps,
  i18n,
  logger,
  metrics,
  userContext,
}: Props) => (
  <LoggingContext.Provider value={logger}>
    <MetricsContext.Provider value={metrics}>
      <AppErrorBoundary errorPage={errorPage}>
        <ElementEventTrackerContext.Provider value={elementEventTracker}>
          <BackendContext.Provider value={backendGateway}>
            <StaticApplicationShell
              i18n={i18n}
              configs={configs}
              userContext={userContext}
              featureTestsMapping={featureTestsMapping}
              googleMaps={googleMaps}
            >
              {children}
            </StaticApplicationShell>
          </BackendContext.Provider>
        </ElementEventTrackerContext.Provider>
      </AppErrorBoundary>
    </MetricsContext.Provider>
  </LoggingContext.Provider>
);

export default BrowserApplicationShell;
