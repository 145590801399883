import reactReplacePlaceholder from 'react-replace-placeholder';

import makePluralKey, {
  DEFAULT_MAX_VARIANTS,
  DEFAULT_PLURAL_SUFFIX,
} from './make-translations-plural-key';

import type {
  TranslationServiceType,
  Translate,
  TranslatePlural,
} from '../../types/i18n/translation-service';

const TranslationService: TranslationServiceType = (translations) => {
  const translate: Translate = (
    key: string,
    replacements = {},
    startTag = '{',
    endTag = '}',
  ) => {
    const message = translations[key];
    if (!message) {
      // eslint-disable-next-line no-console
      console.error(`Missing translation for key "${key}"`);
      return '';
    }
    if (!replacements || Object.keys(replacements).length === 0) {
      return message;
    }
    if (
      Object.values(replacements).some(
        (v) => v !== null && typeof v === 'object',
      )
    ) {
      throw new Error(
        'For replacements containing objects (React component or html element), use the FormattedMessage component instead',
      );
    }

    return reactReplacePlaceholder(
      message,
      replacements,
      startTag,
      endTag,
    ).join('');
  };

  const translatePlural: TranslatePlural = (
    key,
    cardinality,
    replacementKey,
    extraReplacements = {},
    maxVariants = DEFAULT_MAX_VARIANTS,
    extra = DEFAULT_PLURAL_SUFFIX,
    startTag = '{',
    endTag = '}',
  ) => {
    const pluralKey = makePluralKey(key, cardinality, maxVariants, extra);

    const replacements = { ...(extraReplacements || {}) };
    if (cardinality > maxVariants) {
      replacements[replacementKey] = cardinality;
    }
    return translate(pluralKey, replacements, startTag, endTag);
  };

  return {
    translate,
    translatePlural,
  };
};

export default TranslationService;
