/* eslint-disable no-underscore-dangle */
import observer from 'saddlebag-observer';
import smoothscroll from 'smoothscroll-polyfill';

import { getStringParam } from 'common/src/services/url/url';
import { parseDate } from 'common/src/utils/machine-date-utils';
import PageTypes from 'common/src/pageTypes';
import type { Stay } from 'common/src/types/utils';
import { getDefaultedStayFromUrl } from 'common/src/services/url/get-stay-from-url';

import logger from './services/logger';
import { getEntityIdFromUrl } from './services/url/get-entity-id-from-url';
import { storeCashBackUserId } from './services/cash-back-user-id';
import EventSource from './services/event-source';
import BackendGateway from './services/backend-gateway';
import startSkyscannerPage from './skyscanner-application';

import 'custom-event-polyfill';

smoothscroll.polyfill();

if (!window.__internal) {
  throw new Error(
    'Client only development flow is not supported, please start the development server',
  );
}

startSkyscannerPage({
  errorSource: 'hotels',
  environment: window.__internal.clientEnv,
  // @ts-expect-error Type '(props: PageProps) => Element' provides no match for the signature 'new (props: {}, context?: any): Component<{}, any, any>'.
  importApp: async () => {
    const { BrowserRoutedApp } = await import(
      /* webpackMode: "eager" */ './HotelsFunnelApplication'
    );
    return BrowserRoutedApp;
  },
  extraProps: ({ i18n, props }) => {
    const {
      stay: stayProp,
      userContext: { pageType },
    } = props;
    let stay =
      pageType === PageTypes.DBOOK
        ? stayProp
        : (getDefaultedStayFromUrl(window.location.href) as Stay);

    if (stay && stay.checkIn && stay.checkOut) {
      const { checkIn, checkOut } = stay;
      stay = {
        ...stay,
        checkIn: parseDate(checkIn),
        checkOut: parseDate(checkOut),
      };
    }

    // @ts-expect-error TS2339: Property 'entityId' does not exist on type 'PageProps'.
    const entityId = !props.entityId
      ? getEntityIdFromUrl(window.location.href)
      : // @ts-expect-error TS2339: Property 'entityId' does not exist on type 'PageProps'.
        props.entityId;

    const cashBackUserId = getStringParam(
      window.location.href,
      'cashback_user_id',
    );
    if (cashBackUserId) {
      storeCashBackUserId(cashBackUserId);
    }

    const backendGateway = BackendGateway({
      ...window.__internal.backendGateway,
      eventSource: EventSource(
        logger,
        window.__internal.eventSourcePolyfillEnabled,
      ),
      i18n,
    });

    // Publish GA event for distilled experiments
    const { name, variant } = window.__internal.distilledExperiment || {};
    if (name && variant) {
      observer.publish('tracking-event', {
        ga: [
          {
            category: `${window.__internal.pageType}-results-distilled-experiment`,
            action: variant,
            label: `${name} Results Variant ${variant.toUpperCase()}`,
          },
        ],
      });
    }

    return {
      stay,
      entityId,
      i18n,
      backendGateway,
    };
  },
});
