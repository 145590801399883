import React from 'react';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../i18n-context';
import FormattedMessage from '../FormattedMessage';

import type {
  DistanceOptions,
  ObjectMappingEnhance,
} from '../../../components/types';

const MARKETS_USING_MILES = [
  'LR', // Liberia
  'MM', // Myanmar
  'UK', // United Kingdom
  'US', // United States
  'AS', // American Samoa
  'BS', // Bahamas
  'BZ', // Belize
  'VG', // British Virgin Islands
  'KY', // Cayman Islands
  'DM', // Dominica
  'FK', // Falkland Islands
  'GD', // Grenada
  'GU', // Guam
  'MP', // The North Mariana Islands
  'WS', // SAMOA
  'LC', // St. Lucia
  'VC', // St. Vincent & The Grenadines
  'SH', // St. Helena
  'KN', // St. Kitts & Nevis
  'TC', // Turks & Caicos Islands
  'VI', // U.S. Virgin Islands
];

type Props = {
  i18n: I18nService;
  data?: ObjectMappingEnhance;
  label?: string;
  distancePlaceholder?: string;
  distanceMeters: number;
  options?: DistanceOptions;
};

const formatDistance = ({
  distanceMeters,
  kmString,
  market,
  milesString,
}: {
  milesString: string;
  kmString: string;
  market: string;
  distanceMeters: number;
}) => {
  if (MARKETS_USING_MILES.includes(market)) {
    const miles = (distanceMeters / 1000) * 0.621371;
    return { key: milesString, distance: miles };
  }

  const km = distanceMeters / 1000;
  return { key: kmString, distance: km };
};

const FormattedDistance = ({
  data,
  distanceMeters,
  distancePlaceholder = 'distance',
  i18n,
  label,
  options = {},
}: Props) => {
  const {
    kmString = 'DISTANCE_KILOMETRES',
    milesString = 'DISTANCE_MILES',
    replace = (val: string) => val,
  } = options;

  const { distance, key } = formatDistance({
    milesString,
    kmString,
    market: i18n.culture.market,
    distanceMeters,
  });

  const formattedDistance = i18n.formatNumber(distance, 2);

  if (label) {
    return (
      <FormattedMessage
        label={label}
        data={{
          [distancePlaceholder]: replace(
            i18n.translate(key, { distance: formattedDistance }),
          ),
          ...data,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      label={key}
      data={{ [distancePlaceholder]: replace(formattedDistance), ...data }}
    />
  );
};

export default withI18n(FormattedDistance);
