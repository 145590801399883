import dateFnsParse from 'date-fns/parseISO';
import dateFnsFormat from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import type { DateTime } from '../types/utils';

export const parseDate = (dateStr: DateTime) => {
  if (typeof dateStr === 'string') {
    return dateFnsParse(dateStr);
  }
  return dateStr;
};

// returns a readable machine date ignoring locale
// this function should be only used for server side, use the one in i18n for client side
export const formatDate = (date: DateTime, format = 'yyyy-MM-dd') => {
  const definitelyDate = parseDate(date);
  return dateFnsFormat(definitelyDate, format);
};

export const differenceDaysLength = (fromDate: DateTime, toDate: DateTime) => {
  const length = differenceInCalendarDays(
    parseDate(fromDate),
    parseDate(toDate),
  );
  if (Number.isNaN(length)) {
    return 0;
  }
  return length;
};

export const getCurrentMonthNumDays = (
  year: number | null,
  month: number | null,
) => {
  let monthDay = 31;
  if (year && month) {
    switch (month) {
      case 3:
      case 5:
      case 8:
      case 10:
        monthDay = 30;
        break;
      case 1:
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
          monthDay = 29;
        } else {
          monthDay = 28;
        }
        break;
      default:
        monthDay = 31;
    }
  }
  return monthDay;
};
