// EventSource doesn't seem to support passing additional headers yet,
// but we need to pass `X-Gateway-Servedby` in the http headers
// to BFF requests to resolve the cross region of Bellboy
import { EventSourcePolyfill } from 'event-source-polyfill';

import type { Logger } from 'common/src/types/utils';

import type { EventSource as EventSourceType } from '../types/event-source';
import type {
  EventSourcePolyfill as EventSourcePolyfillType,
  MessageEvent,
} from 'event-source-polyfill';

const errMsg = 'EventSource error';
const MAX_DIFF = 1000;

const redirectIfPerimeterXBlocked = async (url: string) => {
  // If PerimeterX bot blocking triggers, Gateway will return a 403 error
  // with { reason: "blocked" } payload. Because EventSource doesn't let us
  // access the underlying error we have to fetch() the URL again to inspect
  // the response every time there is a failure to check for this. If the request
  // was indeed blocked we redirect to a captcha page indicated in the response,
  // otherwise we carry on with error handling as usual.
  try {
    const res = await fetch(url);
    if (res.status === 403) {
      const body = await res.json();
      if (body && body.reason === 'blocked') {
        window.location.assign(body.redirect_to);
        return true;
      }
    }
  } catch (error) {
    // Do nothing on error. EventSource probably failed for a different reason
    // so we should carry on with error handling as normal.
  }
  return false;
};

const EventSourceAdapter = (
  logger: Logger,
  eventSourcePolyfillEnabled = false,
  maxInterval = 30000,
): EventSourceType => ({
  start: (url, name, cb, opt) => {
    const eventSource = (
      eventSourcePolyfillEnabled
        ? new EventSourcePolyfill(url, opt)
        : new EventSource(url)
    ) as EventSourcePolyfillType;
    let currentTimeout: ReturnType<typeof setTimeout>;
    let seenNewData = false;

    const closeEventSource = () => {
      seenNewData = true;
      eventSource.close();
      clearTimeout(currentTimeout);
    };

    const createTimeout = () => {
      clearTimeout(currentTimeout);
      seenNewData = false;

      const startTime = Date.now();

      currentTimeout = setTimeout(() => {
        // When a device (especially mobile devices) go to sleep, timeout listeners can be paused by the OS,
        // when the device wakes up again the timeouts are often fired even if the device has been asleep
        // for much longer than the setTimeout interval, to get around this here we check the elapsed time
        // to see if the device has been asleep
        const endTime = Date.now();
        const elapsedTime = endTime - startTime;
        const diff = elapsedTime - maxInterval;

        // If we think the device has been asleep
        // we create a new timeout as EventSource should reconnect at
        // the same time to look for more data
        if (diff > MAX_DIFF) {
          createTimeout();
          return;
        }

        if (!seenNewData && diff < MAX_DIFF) {
          closeEventSource();
          const error = new Error('Timeout error');
          logger.warn(`${errMsg}: timeout`, { url, name });
          cb(error);
        }
      }, maxInterval);
    };
    createTimeout();

    // @ts-expect-error TS2769: No overload matches this call. To fix it we need to update name to always use 'message'
    eventSource.addEventListener(name, (message: MessageEvent) => {
      seenNewData = true;

      try {
        const data = JSON.parse(message.data);
        const metaData = { eventId: message.lastEventId || null };
        cb(null, data, metaData);
      } catch (err) {
        logger.error(errMsg, { url, data: message.data, name }, err);
      }
      createTimeout();
    });

    eventSource.addEventListener('error', async () => {
      const { readyState } = eventSource;
      const errData = { url, name, readyState };

      if (readyState === 2) {
        closeEventSource();
        const redirected = await redirectIfPerimeterXBlocked(url);
        if (!redirected) {
          const err = new Error('EventSource closed');
          logger.warn(`${errMsg}: closed`, errData);
          cb(err);
        }
      } else {
        logger.warn(errMsg, errData);
      }
    });

    eventSource.addEventListener('end', closeEventSource);

    return closeEventSource;
  },
});

export default EventSourceAdapter;
