// https://skyscanner-prod-sttc-int-eu-west-1.s3.amazonaws.com/sttc/schemadocs/index.html#autosuggest.Telemetry
import type { Maybe } from 'common/src/types/utils';
import type { Culture } from 'common/src/types/context';

import { RESULT_TYPE } from './constants';

export const AUTOSUGGEST_TELEMETRY_SCHEMA_NAME = 'autosuggest.TelemetryEvent';
export const AUTOSUGGEST_MINIEVENT_NAME = 'AutosuggestTelemetry';

type QueryType = {
  prefilledQuery: string;
  isRecentIncluded: boolean;
};

const buildCultureSettings = (culture: Culture) => {
  if (!culture) {
    return {};
  }

  return {
    culture_settings: {
      country: culture.market,
      locale: culture.locale,
      currency: culture.currency,
    },
  };
};

const buildCommonTelemetryProps = (culture: Culture) => ({
  ...buildCultureSettings(culture),
  programme: 'HOTELS',
});

export const buildControlsActivatedMessage = (
  query: QueryType,
  culture: Culture,
) => ({
  ...buildCommonTelemetryProps(culture),
  controls_activated: {
    prefilled_query: query.prefilledQuery,
    is_recent_included: query.isRecentIncluded,
  },
});

export const buildResultSelectedMessage = (
  query: Maybe<string>,
  suggestionValue: string,
  suggestionIndex: number,
  entityId: string,
  culture: Culture,
  resultType = RESULT_TYPE.UNSET_RESULT_TYPE,
) => ({
  ...buildCommonTelemetryProps(culture),
  result_selected: {
    query,
    selected_entity_id: entityId,
    selected_value: suggestionValue,
    position: suggestionIndex + 1,
    selected_result_type: resultType,
  },
});

export const buildResultsUpdatedMessage = (
  query: Maybe<string>,
  numResults: number,
  culture: Culture,
  suggestedEntityIds: string,
) => ({
  ...buildCommonTelemetryProps(culture),
  results_updated: {
    query,
    num_results: numResults,
    suggested_entity_ids: suggestedEntityIds,
  },
});

export const buildControlsDismissedMessage = (
  query: Maybe<string>,
  culture: Culture,
) => ({
  ...buildCommonTelemetryProps(culture),
  controls_dismissed: {
    query,
  },
});
