import React from 'react';

import reactReplacePlaceholder from 'react-replace-placeholder';

import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../i18n-context';

import type { ObjectMappingEnhance, Maybe } from '../../../components/types';

type Props = {
  i18n: I18nService;
  data?: ObjectMappingEnhance;
  label: string;
  replacementKey?: string;
  extra?: string;
  startTag?: string;
  endTag?: string;
  cardinality?: Maybe<number>;
  maxVariants?: number;
  strong?: boolean;
};

const FormattedMessage = ({
  cardinality,
  data,
  endTag,
  extra,
  i18n,
  label,
  maxVariants,
  replacementKey = 'number',
  startTag,
  strong,
}: Props) => {
  const replacements = strong
    ? {
        strong: {
          pair: '/strong',
          // eslint-disable-next-line react/no-unstable-nested-components
          format: (text: string) => (
            <BpkText textStyle={TEXT_STYLES.label1}>{text}</BpkText>
          ),
        },
        ...data,
      }
    : data;

  if (Object.values(replacements || {}).some((v) => typeof v === 'object')) {
    return reactReplacePlaceholder(
      cardinality
        ? i18n.translatePlural(
            label,
            cardinality,
            replacementKey,
            data,
            maxVariants,
            extra,
          )
        : i18n.translations()[label],
      replacements,
      startTag,
      endTag,
    );
  }

  return (
    <>
      {cardinality
        ? i18n.translatePlural(
            label,
            cardinality,
            replacementKey,
            data,
            maxVariants,
            extra,
          )
        : i18n.translate(label, replacements, startTag, endTag)}
    </>
  );
};

FormattedMessage.defaultProps = {
  data: {},
  cardinality: null,
  maxVariants: 9,
  startTag: '{',
  endTag: '}',
  strong: false,
};

export default withI18n(FormattedMessage);
