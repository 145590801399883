import logger from '../../services/logger';

import type { BackendGateway } from '../../types/backend-gateway';

const VERIFY_TOKEN_CALLBACK_FUNCTION_NAME = 'verifyTokenCallback';
const setupAntiReptile = async ({
  backendGateway,
  pageType,
}: {
  backendGateway: BackendGateway;
  pageType: string;
}) => {
  // @ts-expect-error TS2339: Property 'Kar98k' does not exist on type 'Window & typeof globalThis'.
  const fingerprintKeys = window?.Kar98k?.hoteluuidkeys ?? '';

  let isSpider = false;
  try {
    // @ts-expect-error TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'.
    window[VERIFY_TOKEN_CALLBACK_FUNCTION_NAME] = (
      getTokenCallback: () => string,
    ) => {
      const key = getTokenCallback();
      backendGateway
        .verifyToken({
          fingerprintKeys,
          key,
          pageType,
        })
        .then((result: boolean) => {
          isSpider = result;
        });
    };

    const script = await backendGateway.getCallbackScript({
      fingerprintKeys,
      callback: VERIFY_TOKEN_CALLBACK_FUNCTION_NAME,
    });
    /* eslint-disable-next-line no-new-func */
    Function(script)();
  } catch (err) {
    logger.warn('Set up anti reptile failed', {});

    return false;
  }

  return isSpider;
};

export default setupAntiReptile;
