const PageTypes = {
  HOME: 'hotels-home',
  SEARCH: 'hotels-results',
  DETAILS: 'hotels-hotel-detail',
  CITY: 'hotels-in-city',
  REGION: 'hotels-in-region',
  PLACE: 'hotels-near-place',
  NEIGHBOURHOOD: 'hotels-in-neighbourhood',
  DBOOK: 'hotels-dbook',
  PRESALE: 'hotels-pre-sale',
  STAR: 'star-hotels-in-city',
  DEALS: 'hotels-deals',
  BRAND: 'hotels-brand-in-city',
};

export default PageTypes;
