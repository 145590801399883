import React from 'react';
import type { ReactElement } from 'react';

import type {
  GoogleMaps,
  FeatureTestsMapping,
} from 'common/src/types/client-side-params';
import type { Configs } from 'common/src/types/configs';
import type { I18nService } from 'common/src/types/i18n';
import type { UserContext as UserContextType } from 'common/src/types/user-context';

import UserContext from '../../components/UserContext';
import GoogleMapsContext from '../../components/google-maps-context';
import ConfigurationContext from '../configuration';
import FeatureTestContext from '../feature-tests';
import FeatureTests from '../feature-tests/feature-tests';
import { I18nContext } from '../i18n';

type Props = {
  i18n: I18nService;
  children: ReactElement;
  configs: Configs;
  userContext: UserContextType;
  featureTestsMapping: FeatureTestsMapping;
  googleMaps: GoogleMaps;
};

const StaticApplicationShell = ({
  children,
  configs,
  featureTestsMapping,
  googleMaps,
  i18n,
  userContext,
}: Props) => (
  <GoogleMapsContext.Provider value={googleMaps}>
    <FeatureTestContext.Provider value={FeatureTests(featureTestsMapping)}>
      <I18nContext.Provider value={i18n}>
        <ConfigurationContext.Provider value={configs}>
          <UserContext.Provider value={userContext}>
            {children}
          </UserContext.Provider>
        </ConfigurationContext.Provider>
      </I18nContext.Provider>
    </FeatureTestContext.Provider>
  </GoogleMapsContext.Provider>
);

export default StaticApplicationShell;
