/* eslint-disable no-underscore-dangle */
import { afterDomContentLoaded } from 'saddlebag-browser';

import I18nService from 'common/src/services/i18n/i18n-service';
import {
  getUtmSource,
  isFromFlightDBookEmail,
  trafficSource as getTrafficSource,
} from 'common/src/services/traffic-source';
import type { Environment } from 'common/src/types/utils';
import type { PageProps } from 'common/src/types/client-side-params';
import type { I18nService as I18nServiceType } from 'common/src/types/i18n';

import logger from '../../services/logger';
import ElementEventTracker from '../element-events/element-event-tracker';
import ApplicationMetrics from '../application-metrics/application-metrics';
import { getCurrentQueryParam } from '../../services/url/url';
import fallbackTranslations from '../../strings-fallback/translations.json';

import buildObserverClient from './build-observer-client';
import setupErrorHandling from './setup-error-handling';
import setupReactPage from './setup-react-page';
import setupAntiReptile from './setup-anti-reptile';

import type { ImportApp, ExtraProps } from '../types';

type Props = {
  environment: Environment;
  errorSource: string;
  extraProps: ({
    i18n,
    props,
  }: {
    props: PageProps;
    i18n: I18nServiceType;
  }) => ExtraProps;
  importApp: ImportApp;
};

const SkyscannerPage = ({
  environment,
  errorSource,
  extraProps,
  importApp,
}: Props) => {
  setupErrorHandling({ logger });
  const observerClient = buildObserverClient({ environment });
  // @ts-expect-error TS2339: Property 'translations' does not exist on type 'ClientSideParams'.
  // since it injects by the separate translations file generated by the command `npm run build:translations`
  const { props: dataProps, translations } = window.__internal;
  const {
    device: deviceInfo,
    isWebView,
    pageType,
    userPreferences,
  } = dataProps.userContext;
  // @ts-expect-error TS2339: Property 'source' does not exist on type 'PageProps'.
  const source = getCurrentQueryParam('source') || dataProps.source;
  const utmSourceInCurrentQuery = getCurrentQueryParam('utm_source');
  const utmSource = utmSourceInCurrentQuery
    ? getUtmSource(utmSourceInCurrentQuery)
    : null;
  const trafficSource = getTrafficSource({ source });

  const elementEventTracker = ElementEventTracker({
    observerClient,
    trafficSource,
    utmSource,
    device: deviceInfo,
    isWebView,
    pageType,
    ...window.__internal.elementEventTracker,
  });

  // @ts-expect-error Property 'region' is missing
  // TODO: remove 'region' from ApplicationMetrics and related functions since it's already deleted from window.__internal.metrics
  const metrics = new ApplicationMetrics({
    observerClient,
    deviceInfo,
    trafficSource,
    elementEventTracker,
    ...window.__internal.metrics,
  });
  const errorPage =
    environment === 'production' ? `/500.ashx?source=${errorSource}` : null;

  try {
    metrics.pageLoadStarted();
    const i18n = I18nService(
      {
        ...window.__internal.i18n,
        translations: translations || fallbackTranslations,
      },
      logger,
    );

    const props = Object.assign(dataProps, {
      errorPage,
      logger,
      metrics,
      elementEventTracker,
      ...extraProps({ props: dataProps, i18n }),
    });

    setupReactPage({
      hydrateClient: environment !== 'development',
      importApp,
      props,
    });

    // After the page has finished loading
    // Track this event and instantiate any open components that live outside of
    // the React bundle
    afterDomContentLoaded(async () => {
      const { tagManager } = await import(
        /* webpackMode: "lazy" */ './bootstrap'
      );
      tagManager();

      const isSpider = props.configs.ctripAntiReptilesEnabled
        ? await setupAntiReptile({
            backendGateway: props.backendGateway,
            pageType,
          })
        : false;

      metrics.pageLoadSuccess({
        market: i18n.culture.market,
        isFromFlightDBookEmail: isFromFlightDBookEmail(source),
        fallbackTranslations: !translations,
        isLoggedIn: !!userPreferences && userPreferences.isLoggedIn,
        isSpider,
        // TODO: unify the type declaration of Stay
        stay: dataProps.stay,
      });
    });
  } catch (err) {
    // If anything goes wrong during setup
    // Log the error and redirect the user to a route on our service
    // which only returns an HTTP 500 so they see a Houston page in production
    metrics.pageLoadFailure();
    logger.error('Caught error in page shell', {}, err);

    if (errorPage) {
      window.location.replace(`/500.ashx?source=${errorSource}`);
    }
  }
};

export default SkyscannerPage;
