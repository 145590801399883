import React from 'react';
import type { ComponentType } from 'react';

import displayName from './display-name';

import type { BackendGateway } from '../types/backend-gateway';

type ContextProps = {
  backendGateway: BackendGateway;
};

// @ts-expect-error missing properties
const BackendContext = React.createContext<BackendGateway>({});

export const withBackendContext = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const component = (props: Omit<P, 'backendGateway'>) => (
    <BackendContext.Consumer>
      {(backendGateway: BackendGateway) => (
        // @ts-expect-error we expect that backendGateway can be overwritten in the unit tests
        <Component backendGateway={backendGateway} {...(props as P)} />
      )}
    </BackendContext.Consumer>
  );

  component.displayName = displayName(Component);

  return component;
};

export default BackendContext;
