import queryString from 'query-string';
import { getUtid, getCsrfToken } from 'saddlebag-user-management';

import { now } from 'common/src/utils/wrap-date';
import { StandardURL } from 'common/src/services/url/StandardURL';
import type {
  JsonObject,
  Stay,
  HttpOptions,
  Nullable,
} from 'common/src/types/utils';
import type {
  UserContext,
  UserPreferences,
} from 'common/src/types/user-context';
import type { I18nService } from 'common/src/types/i18n';

import { DATE_FORMAT } from '../skyscanner-application/i18n';

import logger from './logger';
import instrumentedFetch from './instrumented-fetch';
import {
  X_GATEWAY_SERVED_BY,
  getXGatewayServedBy,
  storeXGatewayServedBy,
} from './x-gateway-servedby';
import { getCashBackUserId } from './cash-back-user-id';

import type { EventSource } from '../types/event-source';
import type { EventSourcePolyfillInit } from 'event-source-polyfill';
import type { BackendGateway as BackendGatewayType } from '../types/backend-gateway';

const getQueryParamStr = (obj: JsonObject) => queryString.stringify(obj);

type QueryParams = {
  [key: string]: string | number | boolean | null | undefined | UserPreferences;
};

type Props = {
  backendUrl: string;
  eventSource: EventSource;
  i18n: I18nService;
  userContext: UserContext;
};

const BackendGateway = ({
  backendUrl,
  eventSource,
  i18n,
  userContext,
}: Props): BackendGatewayType => {
  let xGatewayServedBy = getXGatewayServedBy();

  const getCommonOpts = (
    options?: HttpOptions,
    csrfToken = false,
  ): HttpOptions | undefined => {
    if (!xGatewayServedBy && !csrfToken) {
      return options;
    }

    const finalHeaders: JsonObject = {};
    const { headers = {}, ...rest } = options || {};

    if (csrfToken) {
      finalHeaders['x-csrf-token'] = getCsrfToken();
    }
    if (xGatewayServedBy) {
      finalHeaders[X_GATEWAY_SERVED_BY] = xGatewayServedBy;
    }

    return { headers: { ...finalHeaders, ...headers }, ...rest };
  };

  const builtInFetch = async (
    url: string,
    options?: HttpOptions,
    csrfToken?: boolean,
  ) => {
    const res = await instrumentedFetch(url, getCommonOpts(options, csrfToken));
    xGatewayServedBy = res.headers.get(X_GATEWAY_SERVED_BY);
    storeXGatewayServedBy(xGatewayServedBy);
    return res;
  };

  const getBackendUrl = (path: string, queryParams: QueryParams = {}) => {
    const { userPreferences, ...restParams } = queryParams;
    if (userPreferences) {
      Object.assign(userContext.userPreferences, userPreferences);
    }

    const params = userContext
      ? {
          ...restParams,
          user_context: JSON.stringify(userContext),
        }
      : restParams;

    return new StandardURL(
      `${backendUrl}${path}?${getQueryParamStr(params as JsonObject)}`,
    ).href;
  };

  const getCommonUrlParams = (stay: Stay) => {
    const { culture, formatDate } = i18n;
    const { childrenAges } = stay;

    return {
      market: culture.market,
      currency: culture.currency,
      locale: culture.locale,
      checkin: formatDate(stay.checkIn, DATE_FORMAT.NON_LOCALISED_SHORT),
      checkout: formatDate(stay.checkOut, DATE_FORMAT.NON_LOCALISED_SHORT),
      adults: stay.numberOfAdults,
      rooms: stay.numberOfRooms,
      children_ages:
        childrenAges && childrenAges.length
          ? childrenAges.join(',')
          : undefined,
    };
  };

  const getSearchUrlParams = (
    entityId: string,
    stay: Stay,
    offset: number,
    count: number,
    skyscannerNodeCode?: Nullable<string>,
  ) => ({
    ...getCommonUrlParams(stay),
    entity_id: entityId,
    skyscanner_node_code: skyscannerNodeCode,
    offset,
    count,
  });

  const getSearchUrl = (params: QueryParams) =>
    getBackendUrl('/search/v2', params);

  const getStaticSearchUrl = (params: JsonObject) =>
    getBackendUrl('/search/v2/static', params);

  const getRecommendationUrlParams = (
    entityId: string,
    stay: Stay,
    searchCycleId?: string | null,
  ) => ({
    ...getCommonUrlParams(stay),
    entity_id: entityId,
    search_id: searchCycleId,
  });

  const getPricesUrl = (
    hotelId: string,
    stay: Stay,
    searchEntityId?: string | null,
    searchCycleId?: string | null,
    minPriceRoomId?: string | null,
    ignoreCache?: Nullable<number>,
    priceType?: Nullable<string>,
    userPreferences?: UserPreferences,
    filters?: any,
    source?: Nullable<string>,
    traceInfo?: string,
    audienceId?: string,
  ) => {
    const params = {
      ...getCommonUrlParams(stay),
      entity_id: searchEntityId,
      search_cycle_id: searchCycleId,
      min_price_room_id: minPriceRoomId,
      ignore_cache: ignoreCache,
      price_type: priceType,
      userPreferences,
      filters: JSON.stringify(filters),
      from_cash_back: !!getCashBackUserId(),
      source,
      trace_info: traceInfo,
      audience_id: audienceId,
    };

    // after changing the stay conditions, the old searchCycleId needs to be removed
    // to avoid getting the previous prices due to the cache
    if (userContext && !searchCycleId) {
      // eslint-disable-next-line no-param-reassign
      delete userContext.searchCycleId;
    }

    if (userContext && userContext.verifyAnonymousJwtEnable) {
      return getBackendUrl(`/prices/v3/${hotelId}`, params);
    }

    return getBackendUrl(`/prices/v2/${hotelId}`, params);
  };

  const getSimilarHotelsUrl = (
    hotelId: string,
    stay: Stay,
    priceType?: Nullable<string>,
  ) => {
    const params = {
      ...getCommonUrlParams(stay),
      price_type: priceType,
    };
    return getBackendUrl(`/similar-hotels/v1/${hotelId}`, params);
  };

  return {
    startSearchUpdates: (
      {
        audienceId,
        bounds = {},
        count,
        entityId,
        extraTraceInfo,
        filters,
        hpaVerification,
        offset,
        priceType,
        skyscannerNodeCode,
        sort,
        stay,
        traceInfo,
        upsortHotels,
        userPreferences,
        ...rest
      },
      cb,
      enableCache,
    ) => {
      const params = getSearchUrlParams(
        entityId,
        stay,
        offset,
        count,
        skyscannerNodeCode,
      );
      const searchParams = {
        ...rest,
        ...params,
        sort,
        filters: JSON.stringify(filters),
        price_type: priceType,
        sw_lat: bounds?.south,
        sw_lng: bounds?.west,
        ne_lat: bounds?.north,
        ne_lng: bounds?.east,
        upsort_hotels: upsortHotels?.split(',')[0],
        trace_info: traceInfo,
        audience_id: audienceId,
        hpa_verification: hpaVerification,
        extra_trace_info: extraTraceInfo,
        from_cash_back: !!getCashBackUserId(),
      };

      if (enableCache) {
        const url = getStaticSearchUrl(searchParams);
        return eventSource.start(
          url,
          'staticSearchState',
          cb,
          getCommonOpts() as EventSourcePolyfillInit,
        );
      }
      const url = getSearchUrl({ ...searchParams, userPreferences });
      return eventSource.start(
        url,
        'searchState',
        cb,
        getCommonOpts() as EventSourcePolyfillInit,
      );
    },

    recommendationResults: async (
      { entityId, stay },
      searchCycleId,
      recommendTypes,
    ) => {
      const params = getRecommendationUrlParams(entityId, stay, searchCycleId);
      const recommendParams = {
        ...params,
        recommend_types: recommendTypes,
      };
      const url = getBackendUrl('/hotels-recommendation/v1', recommendParams);
      const res = await builtInFetch(url);
      return res.json();
    },

    startRecommendationUpdates: ({ entityId, recommendTypes, stay }, cb) => {
      const params = getRecommendationUrlParams(entityId, stay);
      const recommendParams = {
        ...params,
        recommend_types: recommendTypes,
      };

      const url = getBackendUrl('/hotels-recommendation/v2', recommendParams);
      return eventSource.start(
        url,
        'recommendHotelsState',
        cb,
        getCommonOpts() as EventSourcePolyfillInit,
      );
    },

    startMapUpdates: (
      {
        bounds,
        count = userContext.hotelCardListCount || 35,
        entityId,
        filters,
        priceType,
        skyscannerNodeCode,
        stay,
        ...rest
      },
      cb,
    ) => {
      const offset = 0;
      const { east, north, south, west } = bounds || {};

      const params = getSearchUrlParams(
        entityId,
        stay,
        offset,
        count,
        skyscannerNodeCode,
      );

      const mapParams = {
        ...params,
        ...rest,
        sw_lat: south,
        sw_lng: west,
        ne_lat: north,
        ne_lng: east,
        filters: JSON.stringify(filters),
        from_cash_back: !!getCashBackUserId(),
        price_type: priceType,
      };

      const url = getBackendUrl(`/search/v2`, mapParams);
      return eventSource.start(
        url,
        'searchState',
        cb,
        getCommonOpts() as EventSourcePolyfillInit,
      );
    },

    startPricesUpdates: (
      {
        audienceId,
        filters,
        hotelId,
        ignoreCache,
        minPriceRoomId,
        priceType,
        searchCycleId,
        searchEntityId,
        source,
        stay,
        traceInfo,
        userPreferences,
      },
      cb,
    ) => {
      const url = getPricesUrl(
        hotelId,
        stay,
        searchEntityId,
        searchCycleId,
        minPriceRoomId,
        ignoreCache,
        priceType,
        userPreferences,
        filters,
        source,
        traceInfo,
        audienceId,
      );

      return eventSource.start(
        url,
        'pricesState',
        cb,
        getCommonOpts(
          {},
          userContext && userContext.verifyAnonymousJwtEnable,
        ) as EventSourcePolyfillInit,
      );
    },

    startSimilarHotelsUpdates: ({ hotelId, priceType, stay }, cb) => {
      const url = getSimilarHotelsUrl(hotelId, stay, priceType);
      return eventSource.start(
        url,
        'similarHotelsState',
        cb,
        getCommonOpts() as EventSourcePolyfillInit,
      );
    },

    nearbyPoiResultV2: async ({
      entityId,
      lat,
      lon,
      maxDistance,
      minDistance,
      poiNumber,
    }) => {
      const url = getBackendUrl('/pois/v2/nearby', {
        lat,
        lon,
        minDistance,
        maxDistance,
        poiNumber,
        entityId,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    nearbyTransport: async ({ cityId, lat, limit, lon }) => {
      const url = getBackendUrl('/nearby-transport/v1', {
        lat,
        lon,
        limit,
        cityId,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    flexibleDatesResult: async ({ entityIds, stay }) => {
      const { culture } = i18n;
      const { adults, end, rooms, start } = stay;
      const params = {
        market: culture.market,
        currency: culture.currency,
        locale: culture.locale,
        entityIds,
        adults,
        rooms,
        start,
        end,
      };
      const url = getBackendUrl('/flexible-dates/v1', params);
      const res = await builtInFetch(url);
      return res.json();
    },

    realReviews: async ({ hotelId, ...params }) => {
      const url = getBackendUrl(`/reviews/v4/${hotelId}`, {
        locale: i18n.culture.locale,
        market: i18n.culture.market,
        currency: i18n.culture.currency,
        ...params,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    translateReview: async ({ partnerId, partnerReviewId, sourceLocale }) => {
      const url = getBackendUrl(
        `/reviews/v4/translate/${partnerReviewId}/${partnerId}/${sourceLocale}/${i18n.culture.locale}`,
        {
          market: i18n.culture.market,
          currency: i18n.culture.currency,
        },
      );
      const res = await builtInFetch(url);
      return res.json();
    },

    validateEmail: async (email) => {
      logger.event(`email domain: ${email.split('@')[1]}`);
      const url = getBackendUrl(`/validate-email/v1`);
      const res = await builtInFetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });
      return res.json();
    },

    translation: async ({ ...params }) => {
      const url = getBackendUrl(`/translation/v1`, {
        targetLocale: i18n.culture.locale,
        ...params,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    reviewHelpful: async ({
      hotelId,
      isHelpful,
      locale,
      partner,
      partnerReviewId,
    }) => {
      const url = getBackendUrl('/reviews/v1/useful');
      const res = await builtInFetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          partner,
          locale,
          hotel_id: hotelId,
          partner_review_id: partnerReviewId,
          operation_type: isHelpful ? 1 : 0,
        }),
      });
      return res.json();
    },

    policyTranslation: async ({ ...params }) => {
      const url = getBackendUrl(`/v2/policy/translate`, {
        ...params,
        targetLocale: i18n.culture.locale,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    getHotelStatic: async ({ hotelId, onlyImages = false }) => {
      const url = getBackendUrl(
        `/hotel-static/v1/${i18n.culture.locale}/${i18n.culture.market}/${i18n.culture.currency}/${hotelId}`,
        { onlyImages },
      );
      const res = await builtInFetch(url);
      return res.json();
    },

    createDiscount: async ({
      campaignId,
      culture,
      entityId,
      offerCode,
      shouldUpdateUserPreferences = false,
      source,
      stay,
      trackInfo,
    }) => {
      const params = {};
      if (shouldUpdateUserPreferences) {
        Object.assign(params, {
          userPreferences: {
            utid: getUtid(),
            isLoggedIn: true,
          },
        });
      }
      const url = getBackendUrl(`/v1/discount/entity/${entityId}`, {
        entityId,
        offerCode,
        campaignId,
        source,
        locale: culture.locale,
        market: culture.market,
        currency: culture.currency,
        adults: stay.numberOfAdults,
        rooms: stay.numberOfRooms,
        checkIn: i18n.formatDate(stay.checkIn, DATE_FORMAT.NON_LOCALISED_SHORT),
        checkOut: i18n.formatDate(
          stay.checkOut,
          DATE_FORMAT.NON_LOCALISED_SHORT,
        ),
        bookingDate: i18n.formatDate(now(), DATE_FORMAT.NON_LOCALISED_SHORT),
        trackInfo: JSON.stringify(trackInfo),
        ...params,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    deleteHistoryHotels: async ({ histories }) => {
      const url = getBackendUrl(`/viewed-hotels/v1/delete-hotels`);
      const res = await builtInFetch(
        url,
        {
          method: 'POST',
          body: JSON.stringify({
            all: histories && histories.length === 0,
            histories,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        true,
      );
      return res.json();
    },

    searchViewedHotels: async ({ priceType, stay }) => {
      const params = getCommonUrlParams(stay);
      const url = getBackendUrl('/viewed-hotels/v1/search-viewed-hotels', {
        ...params,
        price_type: priceType,
      });
      const response = await builtInFetch(url, {}, true);
      return response.json();
    },

    saveViewedHotels: async ({
      cityId,
      destinationId,
      historyPrice,
      hotelId,
    }) => {
      const url = getBackendUrl(`/viewed-hotels/v1/save-hotels`);
      const res = await builtInFetch(
        url,
        {
          method: 'POST',
          body: JSON.stringify({
            cityId,
            currency: i18n.culture.currency,
            destinationId,
            historyPrice,
            hotelId,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        true,
      );
      return res.json();
    },

    poisPrice: async ({ entityId }) => {
      const url = getBackendUrl('/pois/v1/poi-price', {
        cityId: entityId,
        locale: i18n.culture.locale,
        currency: i18n.culture.currency,
      });
      const response = await builtInFetch(url);
      if (!response.ok) {
        return [];
      }
      return response.json();
    },

    poisEntity: async ({ entityIds }) => {
      const url = getBackendUrl('/pois/v1/entity', {
        entities: entityIds,
        locale: i18n.culture.locale,
      });
      const response = await builtInFetch(url);
      return response.json();
    },

    startDealsUpdates: (_, cb) => {
      const { culture } = i18n;
      const params = {
        market: culture.market,
        currency: culture.currency,
        locale: culture.locale,
      };
      const url = getBackendUrl('/hotels-deals/v1', params);
      return eventSource.start(
        url,
        'dealsState',
        cb,
        getCommonOpts() as EventSourcePolyfillInit,
      );
    },

    getCallbackScript: async ({ callback, fingerprintKeys }) => {
      const url = getBackendUrl('/anti-reptile/v1/script', {
        fingerprintKeys,
        callback,
      });
      const response = await builtInFetch(url);
      return response.json();
    },

    verifyToken: async ({ fingerprintKeys, key, pageType }) => {
      const url = getBackendUrl('/anti-reptile/v1/verify', {
        fingerprintKeys,
        key,
        pageType,
      });
      const response = await builtInFetch(url);
      return response.json();
    },
  };
};

export default BackendGateway;
