import type { Logger } from '../../types/logger';

const isExternalSourceError = (filename: string) => {
  // It is a script from PerimeterX and suspect it's a common thing that we just ignore.
  // More: https://skyscanner.atlassian.net/browse/BD-7169
  if (filename.includes('rf8vapwA/init.js')) {
    return true;
  }
  const injectedScriptSuffix = 'line 1 > injectedScript';
  if (filename.endsWith(injectedScriptSuffix)) {
    return true;
  }
  const errorWhitelist = [
    'www.skyscanner',
    'js.skyscnr.com',
    'css.skyscnr.com',
    'images.skyscnr.com',
    'skyscannertools.net',
  ];

  return !errorWhitelist.some((whitelist) => filename.indexOf(whitelist) > -1);
};

const setupErrorHandling = ({ logger }: { logger: Logger }) => {
  /*
   * Setup Error Handling on Page
   */
  window.addEventListener('error', (event) => {
    if (isExternalSourceError(event.filename)) {
      logger.warn(`Uncaught external error: ${event.message}`, {
        filename: event.filename,
        lineNumber: `${event.lineno}`,
        columnNumber: `${event.colno}`,
      });
      return;
    }
    logger.error(
      `Uncaught error: ${event.message}`,
      {
        filename: event.filename,
        lineNumber: `${event.lineno}`,
        columnNumber: `${event.colno}`,
      },
      event.error,
    );
  });

  /** *
  Page Rejection Handler
  ** */
  window.addEventListener('unhandledrejection', (event) => {
    logger.error(`Unhandled rejection: ${event.reason}`, {}, event.reason);
  });
};

export default setupErrorHandling;
