import limitStack from './limit-stack';

const NOT_FOUND_ERROR_NAME = 'NotFoundError';

const updatedErrorStack = (error: Error, componentStack: string) => {
  if (error.name === NOT_FOUND_ERROR_NAME) {
    // eslint-disable-next-line no-param-reassign
    error.stack = componentStack;
    return {
      stackData: {},
      error,
    };
  }
  return {
    // It the stack filed is too long, new relic will ignore it
    stackData: { componentStack: limitStack(componentStack) },
    error,
  };
};

export default updatedErrorStack;
