import React from 'react';
import type { ComponentType } from 'react';

import type { I18nService } from 'common/src/types/i18n';

import displayName from '../../components/display-name';

interface ContextProps {
  i18n: I18nService;
}

// @ts-expect-error missing properties
const I18nContext = React.createContext<I18nService>({});

export const withI18n = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const component = (props: Omit<P, 'i18n'>) => (
    <I18nContext.Consumer>
      {(i18n: I18nService) => (
        // @ts-expect-error we expect that it can be overwritten in the unit tests
        <Component i18n={i18n} {...(props as P)} />
      )}
    </I18nContext.Consumer>
  );

  component.displayName = displayName(Component);

  return component;
};

export default I18nContext;
