import type { Device } from 'common/src/types/user-context';

const getDeviceType = (device?: Device, isWebView: boolean = false) => {
  if (isWebView) {
    const osName = device?.osName;
    if (osName && ['IPADOS', 'IOS'].includes(osName.toUpperCase())) {
      return 'ios';
    }
    if (osName?.toUpperCase() === 'ANDROID') {
      return 'android';
    }
  }

  if (device?.isMobilePhone) {
    return 'mobile';
  }
  if (device?.isTablet) {
    return 'tablet';
  }
  return 'desktop';
};

export default getDeviceType;
