import { GLOBAL } from 'saddlebag-browser';
import { logError, logWarn, logOperationalEvent } from 'saddlebag-logger';

import namespace from '../namespace';

import type { Logger as LoggerType } from '../types/logger';

const $window: Window = GLOBAL.getWindow();
// eslint-disable-next-line no-underscore-dangle
const { logging } = $window.__internal || {};

const wrapError = (msg: string, error?: Error | unknown) => {
  if (error instanceof Error) {
    return error;
  }
  return new Error(msg);
};

const Logger: LoggerType = {
  // 1. For events to view in dashboards or alert
  // 2. For non-warning or non-error logging
  event: (eventName, data) => {
    logOperationalEvent({
      ...data,
      ...logging,
      component: namespace,
      eventName,
    });
  },

  warn: (msg, data) => {
    logWarn({
      ...data,
      ...logging,
      component: namespace,
      message: msg,
    });
  },

  error: (msg, data, error) => {
    const errorObject = wrapError(msg, error);

    logError(errorObject, {
      ...data,
      ...logging,
      component: namespace,
      message: msg,
    });
  },
};

export default Logger;
