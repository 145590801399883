import Localisation, {
  currency as currencyFeatures,
  number as numberFeatures,
  date as dateFeatures,
} from 'saddlebag-localisation';

import { formatDate, parseDate } from '../../utils/machine-date-utils';

import { DATE_FORMAT } from './date-formats';

import type { LocalisationServiceType } from '../../types/i18n/localisation-service';

const LocalisationService: LocalisationServiceType = (
  cldrInfo,
  culture,
  logger,
) => {
  try {
    Localisation.enhance(currencyFeatures, numberFeatures, dateFeatures);
    const saddlebagLocalisation = Localisation(cldrInfo);
    return {
      formatCurrency: (value, format = 'c0') =>
        saddlebagLocalisation.formatCurrency(value, format),
      formatCurrencyFull: (value, format = 'c2') =>
        saddlebagLocalisation.formatCurrencyFull(value, format),
      formatNumber: (value, decimals = 1) =>
        saddlebagLocalisation.formatNumber(value, decimals),
      formatDate: (date, format = 'short') => {
        const definitelyDate = parseDate(date);
        if (
          format === DATE_FORMAT.NON_LOCALISED_SHORT ||
          format === DATE_FORMAT.NON_LOCALISED_YEAR_MONTH
        ) {
          return saddlebagLocalisation.formatDateNonlocalised(
            definitelyDate,
            format,
          );
        }
        return saddlebagLocalisation.formatDate(definitelyDate, format);
      },
      getFirstDay: () => saddlebagLocalisation.getFirstDay(),
      getDaysOfWeek: () => saddlebagLocalisation.getDaysOfWeek(),
      getMonthName: (date, format = 'wide') =>
        saddlebagLocalisation.getMonthName(date, format),
      monthNames: (format = 'wide') => saddlebagLocalisation.monthNames(format),
      getCardExpiryPlaceholder: () =>
        saddlebagLocalisation.getCardExpiryPlaceholder(),
      getDatePlaceholder: () => saddlebagLocalisation.getDatePlaceholder(),
    };
  } catch (err) {
    const msg = `Caught an exception when saddlebag localisation initialization: ${err}\n${
      (err as Error).stack
    }`;
    logger.error(msg);

    // eslint-disable-next-line global-require
    const currencySymbols = require('./currency-symbols').default;
    const { currency } = culture;
    const symbol = currencySymbols[currency];
    if (!symbol) {
      throw new Error(`Currency ${currency} cannot be fallback`);
    }

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
    ];
    const daysOfWeek = [
      {
        name: 'Monday',
        nameAbbr: 'Mon',
        nameShort: 'Mo',
        index: 1,
        cldrKey: 'mon',
        isWeekend: false,
      },
      {
        name: 'Tuesday',
        nameAbbr: 'Tue',
        nameShort: 'Tu',
        index: 2,
        cldrKey: 'tue',
        isWeekend: false,
      },
      {
        name: 'Wednesday',
        nameAbbr: 'Wed',
        nameShort: 'We',
        index: 3,
        cldrKey: 'wed',
        isWeekend: false,
      },
      {
        name: 'Thursday',
        nameAbbr: 'Thu',
        nameShort: 'Th',
        index: 4,
        cldrKey: 'thu',
        isWeekend: false,
      },
      {
        name: 'Friday',
        nameAbbr: 'Fri',
        nameShort: 'Fr',
        index: 5,
        cldrKey: 'fri',
        isWeekend: false,
      },
      {
        name: 'Saturday',
        nameAbbr: 'Sat',
        nameShort: 'Sa',
        index: 6,
        cldrKey: 'sat',
        isWeekend: true,
      },
      {
        name: 'Sunday',
        nameAbbr: 'Sun',
        nameShort: 'Su',
        index: 0,
        cldrKey: 'sun',
        isWeekend: true,
      },
    ];
    const formatNumber = (value: number | string, decimals = 1) => {
      const digits = Number(value).toFixed(decimals).split('.');
      const decimal = digits[1];
      const integerChar = digits[0].split('').reverse();
      const integer = integerChar.reduce((result, char, index) => {
        if (index % 3 === 0 && index !== 0) {
          return `${char},${result}`;
        }
        return `${char}${result}`;
      }, '');
      return decimal ? `${integer}.${decimal}` : String(integer);
    };

    return {
      formatCurrency: (value, format = 'c0') => {
        const digits = Number(format.substring(1));
        return `${symbol}${formatNumber(Math.ceil(value), digits)}`;
      },
      formatCurrencyFull: (value, format = 'c2') => {
        const digits = Number(format.substring(1));
        return `${symbol}${formatNumber(value, digits)}`;
      },
      formatNumber,
      formatDate: (date) => formatDate(date),
      getFirstDay: () => 0,
      getDaysOfWeek: () => daysOfWeek,
      getMonthName: (month) => monthNames[month.getMonth()],
      monthNames: () => monthNames,
      getCardExpiryPlaceholder: () => 'mm/yy',
      getDatePlaceholder: () => 'dd/mm/yyyy',
    };
  }
};

export default LocalisationService;
