import React from 'react';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../i18n-context';

type Props = {
  i18n: I18nService;
  price: number;
};

const FormattedCurrencyFull = ({ i18n, price }: Props) => (
  <>{i18n.formatCurrencyFull(price)}</>
);

export default withI18n(FormattedCurrencyFull);
