import { parseDate } from 'common/src/utils/machine-date-utils';

import { getIsGreatPrice } from '../../services/is-great-price';
import { getPositiveAmenitiesEvent } from '../../services/getPositiveAmenities';

import { getYearMonthDay, isLowestPrice } from './hotels-action';
import { CUG_TYPE } from './constants';

import type { HotelsSeenMessage } from '../types';

export const HOTELS_SEEN_SCHEMA_NAME = 'hotels.SearchResultSeenEvent';
export const HOTELS_SEEN_MINIEVENT_NAME = 'HotelsSearchResultSeen';

export const buildHotelsSeenMessage = ({
  audienceId,
  correlationId,
  currency,
  hotel,
  hotelIndex,
  isMobile,
  pageIndex = 0,
  searchId,
  stay,
  traceInfo,
  upSortHotels,
}: HotelsSeenMessage) => {
  const {
    allPricesInfo: { prices } = {},
    id,
    priceInfo: {
      basePrice,
      cug,
      greatPricePercentage,
      partnerId,
      price,
      taxesAndFees,
    } = {},
  } = hotel;

  const amenities = prices && prices[0].amenities;

  const positiveAmenitiesEvent =
    amenities && getPositiveAmenitiesEvent(amenities);

  const isGreatPrice = getIsGreatPrice({
    greatPricePercentage,
    isHeroPrice: true,
  });

  const isUpSortHotel = upSortHotels
    ? upSortHotels.split(',')[0] === id
    : false;
  const cugType =
    (cug && cug.type && cug.type.toUpperCase()) || 'UNSET_CLOSED_USER_GROUP';
  const totalTaxesAndFees = taxesAndFees?.reduce(
    (acc, cur) => acc + cur.total,
    0,
  );

  const data: { [key: string]: any } = {
    hotel_id: id,
    search_id: searchId,
    hotel_sorting_position: parseInt(hotelIndex, 10),
    hotel_sorting_page_num: pageIndex,
    has_cug_deal: !!cug,
    cug_type: CUG_TYPE[cugType] ? CUG_TYPE[cugType] : 'UNSET_CLOSED_USER_GROUP',
    cug_deal_percentage: cug ? cug.discountPercentage : 0,
    is_up_sort_hotel: isUpSortHotel,
    partner_id: partnerId,
    price: price && {
      currency,
      amount: Math.round(price),
      unit: 'WHOLE',
    },
    trace_info: isUpSortHotel ? traceInfo : null,
    is_lowest: isLowestPrice(hotel),
    audience_id: isUpSortHotel ? audienceId : null,
    correlation_id: correlationId,
    base_price: basePrice && {
      currency,
      amount: Math.floor(basePrice),
      unit: 'WHOLE',
    },
    tax_fees: totalTaxesAndFees && {
      currency,
      amount: Math.floor(totalTaxesAndFees),
      unit: 'WHOLE',
    },
    is_cug_area_available: true,
    is_great_price: isMobile ? undefined : isGreatPrice,
    is_amenity_area_available: true,
    positive_amenity_values: positiveAmenitiesEvent,
  };
  if (stay) {
    const { checkIn, checkOut, childrenAges, numberOfAdults, numberOfRooms } =
      stay;
    data.number_of_rooms = numberOfRooms;
    data.number_of_adults = numberOfAdults;
    data.number_of_children = childrenAges.length;
    childrenAges.forEach((childAge, index) => {
      data[`age_of_child_${index + 1}`] = Number(childAge);
    });
    data.checkin_date = getYearMonthDay(parseDate(checkIn));
    data.checkout_date = getYearMonthDay(parseDate(checkOut));
  }
  return data;
};
