import type { ObjectMapping } from '../../components/types';
import type { FeatureTests as FeatureTestsType } from '../types';

const experimentName = (str: string) => {
  const regex = /^(.*)_V[0-9]+$/;
  const matches = regex.exec(str);
  if (!matches) {
    return null;
  }
  return matches[1];
};

const required = (fieldName: string) => {
  throw new Error(`Field ${fieldName} is required`);
};

const FeatureTests = (tests: ObjectMapping = {}): FeatureTestsType => ({
  variant: (
    experiment: string = required('experiment'),
    variant: string = required('variant'),
  ): boolean => {
    if (`${variant}`.toLocaleLowerCase() === 'a') {
      throw new Error('Can\'t test for variant to be "a"');
    }
    const keys = Object.keys(tests);
    const found = keys.find((key) => {
      const expName = experimentName(key);
      return expName && experiment.toLowerCase() === expName.toLowerCase();
    });
    return !!(found && tests[found] === variant);
  },
});

export default FeatureTests;
