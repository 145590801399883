import React from 'react';
import type { ComponentType } from 'react';

import displayName from './display-name';

import type { GoogleMaps } from './types';

type ContextProps = {
  googleMaps: GoogleMaps;
};

// @ts-expect-error missing properties
const GoogleMapsContext = React.createContext<GoogleMaps>({});

const withGoogleMaps = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const component = (props: Omit<P, 'googleMaps'>) => (
    <GoogleMapsContext.Consumer>
      {(googleMaps: GoogleMaps) => (
        // @ts-expect-error we expect that googleMaps can be overwritten in the unit tests
        <Component googleMaps={googleMaps} {...(props as P)} />
      )}
    </GoogleMapsContext.Consumer>
  );

  component.displayName = displayName(Component);

  return component;
};

export default GoogleMapsContext;
export { withGoogleMaps };
