import React from 'react';

import { BrowserRouter, StaticRouter, Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';

import PageTypes from 'common/src/pageTypes';
import type { PageProps as ServerRenderingPageProps } from 'common/src/types/client-side-params';

import retry from './services/retry';
import StaticApplicationShell from './skyscanner-application/react-shell/StaticApplicationShell';
import BrowserApplicationShell from './skyscanner-application/react-shell/BrowserApplicationShell';

import type { PageProps as ClientRenderingPageProps } from './skyscanner-application/types';

const HomePage = loadable(() => retry(() => import('./screens/HomePage')));
const DealsPage = loadable(() => retry(() => import('./screens/DealsPage')));
const SearchPage = loadable(() => retry(() => import('./screens/SearchPage')));
const HotelDetailsPage = loadable(() =>
  retry(() => import('./screens/HotelDetailsPage')),
);
const DbookFunnelPage = loadable(() =>
  retry(() => import('./screens/DbookFunnelPage')),
);
const StarPage = loadable(() => retry(() => import('./screens/StarPage')));
const NearPlacePage = loadable(() =>
  retry(() => import('./screens/NearPlacePage')),
);
const NeighbourhoodPage = loadable(() =>
  retry(() => import('./screens/NeighbourhoodPage')),
);
const PresalePage = loadable(() =>
  retry(() => import('./screens/PresalePage')),
);

const Routes = [
  {
    pageType: PageTypes.HOME,
    urls: ['/', '/:hotels'],
    component: HomePage,
  },
  {
    pageType: PageTypes.DEALS,
    urls: ['/:hotels/deals'],
    component: DealsPage,
  },
  {
    pageType: PageTypes.PRESALE,
    urls: ['/:hotels/pre-sale'],
    component: PresalePage,
  },
  {
    pageType: PageTypes.SEARCH,
    urls: ['/:hotels/search'],
    component: SearchPage,
  },
  {
    pageType: PageTypes.DETAILS,
    urls: [
      '/details/:hotelId',
      '/:hotels/:country/:city/:hotelName/ht-:hotelId',
      '/:hotels/:hotelName/ht-:hotelId',
      '/:market/:locale/:currency/:hotels/:country/:city/:hotelName/ht-:hotelId',
    ],
    component: HotelDetailsPage,
  },
  {
    pageType: PageTypes.PLACE,
    urls: [
      '/:hotels/:country/:city/:place/pl-:placeId',
      '/:market/:locale/:currency/:hotels/:country/:city/:place/pl-:placeId',
    ],
    component: NearPlacePage,
  },
  {
    pageType: PageTypes.NEIGHBOURHOOD,
    urls: [
      '/:hotels/:country/:city/:neighbourhood/ne-:neighbourhoodId',
      '/:market/:locale/:currency/:hotels/:country/:city/:neighbourhood/ne-:neighbourhoodId',
    ],
    component: NeighbourhoodPage,
  },
  {
    pageType: PageTypes.DBOOK,
    urls: [
      '/:hotels/book/:hotelId/:partnerId/checkout/:roomId/:rateId',
      '/:hotels/book/:hotelId/:partnerId/booking/:bookingId',
    ],
    component: DbookFunnelPage,
    methods: ['get', 'post'],
  },
  {
    // Moved to last as the culture url impacts routing to city and details pages
    pageType: PageTypes.HOME,
    urls: ['/:market/:locale/:currency/:hotels'],
    component: HomePage,
  },
  {
    pageType: PageTypes.STAR,
    urls: [
      '/:hotels/:country/:city/:star/ci-:cityId/sr-:starRating',
      '/:market/:locale/:currency/:hotels/:country/:city/:star/ci-:cityId/sr-:starRating',
    ],
    component: StarPage,
  },
];

type Props = ClientRenderingPageProps | ServerRenderingPageProps;

const createRoutes = (props: Props) =>
  Routes.map(({ component: Component, urls }) =>
    urls.map((url) => (
      <Route
        key={url}
        path={url}
        exact
        render={() => <Component {...props} />}
      />
    )),
  );

const BrowserRoutedApp = (props: ClientRenderingPageProps) => {
  const {
    backendGateway,
    configs,
    elementEventTracker,
    errorPage,
    featureTestsMapping,
    googleMaps,
    i18n,
    logger,
    metrics,
    userContext,
  } = props;

  return (
    <BrowserRouter>
      <BrowserApplicationShell
        errorPage={errorPage}
        i18n={i18n}
        configs={configs}
        userContext={userContext}
        featureTestsMapping={featureTestsMapping}
        googleMaps={googleMaps}
        logger={logger}
        backendGateway={backendGateway}
        elementEventTracker={elementEventTracker}
        metrics={metrics}
      >
        <Switch>{createRoutes(props)}</Switch>
      </BrowserApplicationShell>
    </BrowserRouter>
  );
};

const StaticRoutedApp = ({
  props,
  url,
}: {
  props: ServerRenderingPageProps;
  url: string;
}) => {
  // eslint-disable-next-line react/prop-types
  const { configs, featureTestsMapping, googleMaps, i18n, userContext } = props;

  return (
    <StaticRouter location={url} context={{}}>
      <StaticApplicationShell
        i18n={i18n}
        configs={configs}
        userContext={userContext}
        featureTestsMapping={featureTestsMapping}
        googleMaps={googleMaps}
      >
        <Switch>{createRoutes(props)}</Switch>
      </StaticApplicationShell>
    </StaticRouter>
  );
};

export { Routes, BrowserRoutedApp, StaticRoutedApp };
