import React from 'react';
import type { ComponentType } from 'react';

import displayName from '../../components/display-name';

import type { FeatureTests } from '../types';

interface ContextProps {
  featureTests: FeatureTests;
}

// @ts-expect-error missing properties
const FeatureTestContext = React.createContext<FeatureTests>({});

export const withFeatureTests = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const component = (props: Omit<P, 'featureTests'>) => (
    <FeatureTestContext.Consumer>
      {(featureTests: FeatureTests) => (
        // @ts-expect-error we expect that featureTests can be overwritten in the unit tests
        <Component featureTests={featureTests} {...(props as P)} />
      )}
    </FeatureTestContext.Consumer>
  );

  component.displayName = displayName(Component);

  return component;
};

export default FeatureTestContext;
