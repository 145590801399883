import { getCommonsDateTime } from 'saddlebag-grappler';

import type { Nullable, Stay, Maybe } from 'common/src/types/utils';

import {
  ACTION_TYPE,
  PRICE_TYPE,
  SORTING_COLUMNS,
  FILTER_AREA_TYPE,
  REGION,
  FILTER_VALUE_TYPE,
  FILTER_TYPE,
  FILTER_OPTION_TYPE,
  RATES_FILTER_TYPE,
  AUTO_SUGGEST_POI_LIST,
  BASE_ROOM_TYPE,
  SORTING_CLICK_AREA_TYPE,
  RATE_LAYOUT_TYPE,
  CUG_TYPE,
  MESSAGE_STATE,
  BOOKING_PROCESS_STATE,
  BOOKING_STATUS,
  HOTEL_CARD_AREA,
  PRICE_CHANGED_TYPE,
  POLICY_CHANGED_TYPE,
  UNIFIED_INTEGRATION_STEP_TYPE,
  ACTION_STATUS_YPE,
  PARTNER_ID,
  USER_ACCOUNT_TYPES,
  LOGIN_DEAL_PROCESS_STATE,
  CHANGED_CONDITION,
  DEALS_GROUP,
  SCENE_NAMES,
  ACTION_IN_SCENE,
  REVIEW_FILTER_TYPE_MAPPING,
  SECTION_TYPE,
  CLICK_AREA,
  PAYMENT_RESULT_STATUS,
  MULTI_ROOMS_ACTION_TYPE,
  MARKETING_CONSENT_TYPES,
  MARKETING_PROCESS_STATES,
  SEARCH_CAUSE,
  META_PUSH_LOGIN_ACTION_TYPE,
  PANEL_POSITION,
  ENTITY_TYPE,
  SAVE_STATUS_TYPE,
} from './constants';

import type {
  ObjectMapping,
  AutosuggestSuggestion,
} from '../../components/types';
import type { CommonFilterOptionsShape } from '../../screens/SearchPage/types';
import type { CugPrice, HotelCard } from '../../types/hotel-card';
import type { PriceChangedInfo } from '../types';

export const PRICES_LOADED_TYPES: ObjectMapping = {
  ONLY_META: ACTION_TYPE.PRICES_LOADED_META_ONLY,
  META_AND_DBOOK: ACTION_TYPE.PRICES_LOADED_HYBRID,
  ONLY_DBOOK: ACTION_TYPE.PRICES_LOADED_DBOOK_ONLY,
};

export const getYearMonthDay = (date: Date) => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  day: date.getDate(),
});

const getStayField = (stay: Stay) => {
  if (stay && stay.checkIn && stay.checkOut) {
    const { checkIn, checkOut } = stay;
    return {
      checkin: getYearMonthDay(checkIn),
      checkout: getYearMonthDay(checkOut),
    };
  }
  return undefined;
};

const getFilterValue = (filterId: string, filterValue: string[]) => ({
  [filterId]: Object.keys(FILTER_VALUE_TYPE[filterId]).reduce(
    (acc: any, curr: string) => {
      acc[FILTER_VALUE_TYPE[filterId][curr]] = filterValue.includes(curr);
      return acc;
    },
    {},
  ),
});

const getFilterValueWithOption = (
  filterId: string,
  filterValue: string[],
  filterOptions: CommonFilterOptionsShape,
) => ({
  [FILTER_OPTION_TYPE[filterId]]: Object.keys(
    FILTER_VALUE_TYPE[filterId],
  ).reduce((acc: any, curr: string) => {
    const optionValue = {
      is_selected: filterValue.includes(curr),
      is_available: filterOptions.some(
        (opt) => opt.id === curr && opt.count > 0,
      ),
    };
    acc[FILTER_VALUE_TYPE[filterId][curr]] = optionValue;
    return acc;
  }, {}),
});

const getHotelCardArea = ({
  groupKey,
  isMapPanelHotel,
  isRecommendHotel,
  isViewedHotel,
}: {
  groupKey?: string;
  isMapPanelHotel?: boolean;
  isRecommendHotel?: boolean;
  isViewedHotel?: boolean;
}) => {
  if (isMapPanelHotel) {
    return HOTEL_CARD_AREA.MAP_PANEL;
  }
  if (isViewedHotel) {
    return HOTEL_CARD_AREA.VIEWED;
  }
  if (isRecommendHotel) {
    return HOTEL_CARD_AREA.RECOMMENDED;
  }
  if (groupKey) {
    return DEALS_GROUP[groupKey];
  }

  return HOTEL_CARD_AREA.UNSET_HOTEL_CARD_AREA;
};

export const HOTELS_FRONTEND_SCHEMA_NAME = 'hotels_frontend.HotelsAction';
export const HOTELS_FRONTEND_MINIEVENT_NAME = 'HotelsFrontendHotelsAction';

export const buildAdditionalInfoDayViewSearch = ({
  entityID,
  entityName,
  entityType,
  filters,
  isSearchWithChildren,
}: {
  entityID?: number;
  entityName: Maybe<string>;
  entityType: Maybe<string>;
  filters: Maybe<ObjectMapping>;
  isSearchWithChildren: boolean;
}) => ({
  day_view_search: {
    entity_id: entityID,
    entity_name: entityName,
    is_search_with_children: isSearchWithChildren,
    filters,
    entity_type: entityType,
  },
});

export const buildAdditionalInfoRoomInfoModalButtonClicked = ({
  clickArea,
  rateGroupIndex,
  roomGroupIndex,
}: {
  clickArea: string;
  rateGroupIndex: number;
  roomGroupIndex: number;
}) => ({
  room_info_modal_button_clicked: {
    rate_group_index: rateGroupIndex,
    room_group_index: roomGroupIndex,
    click_area: clickArea,
  },
});

export const buildAdditionalInfoDbookOrMetaButtonClicked = ({
  isDbook,
}: {
  isDbook: boolean;
}) => ({
  dbook_or_meta_clicked: {
    is_dbook: isDbook,
  },
});

export const buildAdditionalInfoPriceSeenInfo = ({
  basePrice,
  correlationId,
  cugType,
  currency,
  dayviewCorrelationId,
  discountPercentage,
  hasCug,
  hotelId,
  isAmenityAreaAvailable,
  isCugAreaAvailable,
  isDbook,
  isFiltered,
  isGreatPrice,
  isLowest,
  pageName,
  panelPosition,
  partnerId,
  partnerRanking,
  positiveAmenities,
  price,
  requestId,
  searchId,
  taxFees,
}: {
  panelPosition: string;
  cugType?: string;
  currency?: string;
  isDbook: boolean;
  isFiltered: boolean;
  isLowest: boolean;
  partnerId?: string;
  partnerRanking?: number;
  price?: number;
  correlationId: Maybe<string>;
  dayviewCorrelationId: Maybe<string>;
  hotelId: string;
  pageName?: string;
  basePrice?: number;
  taxFees?: number;
  searchId: Nullable<string>;
  requestId: Maybe<string>;
  isGreatPrice?: boolean;
  discountPercentage?: Nullable<number>;
  isCugAreaAvailable: boolean;
  isAmenityAreaAvailable: boolean;
  positiveAmenities?: string;
  hasCug: boolean;
}) => ({
  price_seen_info: {
    is_dbook: isDbook,
    is_lowest: isLowest,
    is_filtered: isFiltered,
    partner_ranking: partnerRanking,
    partner_id: partnerId,
    panel_position: PANEL_POSITION[panelPosition],
    cug_type:
      CUG_TYPE[cugType?.toUpperCase?.() as string] || CUG_TYPE.UNSET_CUG_TYPE,
    correlation_id: correlationId,
    day_view_correlation_id: dayviewCorrelationId,
    hotel_id: hotelId && parseInt(hotelId, 10),
    price: price && { currency, amount: Math.round(price), unit: 'WHOLE' },
    base_price: basePrice && {
      currency,
      amount: Math.floor(basePrice),
      unit: 'WHOLE',
    },
    tax_fees: taxFees && {
      currency,
      amount: Math.floor(taxFees),
      unit: 'WHOLE',
    },
    page_name: pageName,
    search_id: searchId,
    request_id: requestId,
    is_cug_area_available: isCugAreaAvailable,
    has_cug_deal: hasCug,
    cug_deal_percentage: discountPercentage || 0,
    positive_amenity_values: positiveAmenities,
    is_amenity_area_available: isAmenityAreaAvailable,
    is_great_price: isGreatPrice,
  },
});

export const buildAdditionalInfoPriceClickedInfo = ({
  basePrice,
  clickArea,
  correlationId,
  cugType,
  currency,
  dayviewCorrelationId,
  discountPercentage,
  hasImage,
  hotelId,
  isAmenityAreaAvailable,
  isCugAreaAvailable,
  isDbook,
  isFiltered,
  isGreatPrice,
  isLowest,
  pageName,
  partnerId,
  partnerRanking,
  policyGroupIndex,
  position,
  positiveAmenities,
  price,
  rateFilterValues,
  redirectId,
  roomFilterValue,
  roomGroupIndex,
  roomName,
  taxFees,
}: {
  clickArea?: string;
  cugType?: string;
  currency?: string;
  hasImage?: boolean;
  isDbook: boolean;
  isFiltered?: boolean;
  isLowest?: boolean;
  partnerId?: string;
  policyGroupIndex?: number;
  position?: number;
  partnerRanking?: number;
  positiveAmenities?: string;
  price?: number;
  rateFilterValues?: Maybe<string>;
  roomFilterValue?: Maybe<string>;
  roomGroupIndex?: number;
  roomName?: Nullable<string>;
  correlationId?: Maybe<string>;
  dayviewCorrelationId?: Nullable<string>;
  hotelId: string;
  basePrice?: number;
  taxFees?: number;
  pageName?: string;
  redirectId?: Nullable<string>;
  isGreatPrice?: boolean;
  discountPercentage?: Nullable<number>;
  isCugAreaAvailable?: boolean;
  isAmenityAreaAvailable?: boolean;
}) => ({
  price_clicked_info: {
    is_dbook: isDbook,
    is_lowest: isLowest,
    is_filtered: isFiltered,
    rate_filter_values: rateFilterValues,
    room_filter_value: roomFilterValue,
    ranking: position,
    partner_ranking: partnerRanking,
    partner_id: partnerId,
    price: price && { currency, amount: Math.round(price), unit: 'WHOLE' },
    has_image: hasImage,
    room_name: roomName,
    positive_amenity_values: positiveAmenities,
    is_amenity_area_available: isAmenityAreaAvailable,
    click_area:
      CLICK_AREA[clickArea?.toUpperCase?.() as string] ||
      CLICK_AREA.UNSET_CLICK_AREA,
    cug_type:
      CUG_TYPE[cugType?.toUpperCase?.() as string] || CUG_TYPE.UNSET_CUG_TYPE,
    room_group_index: roomGroupIndex,
    policy_group_index: policyGroupIndex,
    correlation_id: correlationId,
    day_view_correlation_id: dayviewCorrelationId,
    hotel_id: hotelId && parseInt(hotelId, 10),
    base_price: basePrice && {
      currency,
      amount: Math.floor(basePrice),
      unit: 'WHOLE',
    },
    tax_fees: taxFees && {
      currency,
      amount: Math.floor(taxFees),
      unit: 'WHOLE',
    },
    page_name: pageName,
    redirect_id: redirectId,
    is_great_price: isGreatPrice,
    is_cug_area_available: isCugAreaAvailable,
    has_cug_deal: !!discountPercentage,
    cug_deal_percentage: discountPercentage || 0,
  },
});

export const getDayViewHotelCardClickedActionType = (
  funnelType: string,
  mainPriceArea: string,
) => {
  if (funnelType === 'dbook') {
    return ACTION_TYPE.DAY_VIEW_HOTEL_CARD_DBOOK_CLICKED;
  }

  if (mainPriceArea === 'lowestPriceBtn' || mainPriceArea === 'other') {
    return ACTION_TYPE.DAY_VIEW_META_REDIRECT;
  }
  return ACTION_TYPE.DAY_VIEW_HOTEL_CARD_META_CLICKED;
};

export const buildDayViewHotelCardClickedMessage = ({
  correlationId,
  couponType,
  cug,
  currency,
  goToDVEnabled,
  greatPricePercentage,
  groupKey,
  hasCoupon,
  hotelId,
  isCoupon,
  isGoToHD,
  isMainPrice,
  isMapPanelHotel = false,
  isSavedHotel = false,
  isViewedHotel,
  mainPriceArea,
  partnerId,
  price,
  price_type: priceType,
  stars,
}: {
  couponType: string;
  cug?: CugPrice;
  currency: string;
  goToDVEnabled: boolean;
  greatPricePercentage: number;
  groupKey: string;
  hasCoupon: boolean;
  hotelId: string;
  isCoupon: boolean;
  isGoToHD: boolean;
  isMainPrice: boolean;
  isMapPanelHotel: boolean;
  isViewedHotel: boolean;
  isSavedHotel: boolean;
  mainPriceArea: string;
  partnerId: string;
  price: number;
  price_type: string; // eslint-disable-line camelcase
  stars: number;
  correlationId?: string;
}) => {
  const cugType = (cug && cug.type && cug.type.toUpperCase()) || 'null';

  return {
    day_view_hotel_card_clicked: {
      is_go_to_dv: goToDVEnabled,
      has_coupon_in_search_result: hasCoupon,
      is_coupon_hotel: isCoupon,
      is_go_to_hd: isGoToHD,
      is_main_price: isMainPrice,
      main_price_area: mainPriceArea,
      partner_id: partnerId,
      price_type: priceType
        ? PRICE_TYPE[priceType.toUpperCase()]
        : PRICE_TYPE.UNSET_PRICE_TYPE,
      stars,
      coupon_type: couponType,
      is_viewed_hotel: isViewedHotel,
      hotel_card_area: getHotelCardArea({
        isRecommendHotel: false,
        isMapPanelHotel,
        isViewedHotel,
        groupKey,
      }),
      has_cug_deal: !!cug,
      cug_type: CUG_TYPE[cugType] ? CUG_TYPE[cugType] : CUG_TYPE.UNSET_CUG_TYPE,
      cug_deal_percentage: cug && cug.discountPercentage,
      hotel_id: parseInt(hotelId, 10),
      price: { currency, amount: Math.round(price), unit: 'WHOLE' },
      is_great_price: !!greatPricePercentage,
      great_price_percentage: greatPricePercentage,
      correlation_id: correlationId,
      is_saved_hotel: isSavedHotel,
    },
  };
};

export const buildDayViewFiltersUsedMessage = ({
  filterArea,
  filterId: filterIdDefault,
  filterIdByClient,
  filterOptions,
  filterValue,
  originalSearchStartFinishCycleId,
  searchStartFinishCycleId,
}: {
  filterArea?: string;
  filterId: string;
  filterIdByClient?: string;
  filterOptions: CommonFilterOptionsShape;
  filterValue: string[];
  originalSearchStartFinishCycleId: string;
  searchStartFinishCycleId: string;
}) => {
  let filtersUsed = {};
  const filterId = filterIdByClient || filterIdDefault;
  const filterType =
    (filterId && FILTER_TYPE[filterId.toUpperCase()]) ||
    FILTER_TYPE.UNSET_FILTER_TYPE;
  if (FILTER_VALUE_TYPE[filterId] && filterValue.length) {
    if (FILTER_OPTION_TYPE[filterId]) {
      filtersUsed = getFilterValueWithOption(
        filterId,
        filterValue,
        filterOptions,
      );
    } else {
      filtersUsed = getFilterValue(filterId, filterValue);
    }
  }
  return {
    day_view_filters_used: {
      ...filtersUsed,
      filter_type: filterType,
      filter_area_type: filterArea
        ? FILTER_AREA_TYPE[filterArea.toUpperCase()]
        : FILTER_AREA_TYPE.FILTER_PANEL,
      original_search_start_finish_cycle_id: originalSearchStartFinishCycleId,
      search_start_finish_cycle_id: searchStartFinishCycleId,
    },
  };
};

export const buildQuickFilterGroupClickedMessage = (
  filterId: string,
  clickArea: string,
) => ({
  quick_filter_group_clicked: {
    click_area: clickArea,
    filterType:
      FILTER_TYPE[filterId.toUpperCase()] || FILTER_TYPE.UNSET_FILTER_TYPE,
  },
});

export const buildDayViewFiltersEntryClickedMessage = (clickArea: string) => ({
  day_view_filters_entry_click: {
    click_area: clickArea,
  },
});

export const buildDayViewSortUsedMessage = ({
  entityId,
  originalSearchStartFinishCycleId,
  searchStartFinishCycleId,
  selectedValue,
  sortClickArea,
}: {
  entityId: number;
  originalSearchStartFinishCycleId: string;
  searchStartFinishCycleId: string;
  selectedValue: string;
  sortClickArea?: string | null;
}) => ({
  day_view_sort_used: {
    entity_id: entityId,
    selected_sorting_columns: selectedValue
      ? SORTING_COLUMNS[selectedValue.toUpperCase()]
      : 'UNSET_SORTING_COLUMNS',
    original_search_start_finish_cycle_id: originalSearchStartFinishCycleId,
    search_start_finish_cycle_id: searchStartFinishCycleId,
    sorting_click_area: sortClickArea
      ? SORTING_CLICK_AREA_TYPE[sortClickArea]
      : SORTING_CLICK_AREA_TYPE.UNSET_SORTING_CLICK_AREA,
  },
});

export const buildAdditionalInfoPageLoadMessage = ({
  isLoggedIn,
  pageName,
  region,
  stay,
}: {
  isLoggedIn: boolean;
  pageName: string;
  region: string;
  stay: Stay;
}) => ({
  page_load: {
    page_name: pageName,
    region: region ? REGION[region.toUpperCase()] : null,
    is_logged_in: isLoggedIn,
    stay: getStayField(stay),
  },
});

export const buildAdditionalInfoCheckoutBookBtnClickMessage = ({
  accountType,
  bookingId,
  cugType,
  isLoggedIn,
}: {
  accountType?: string;
  bookingId: string;
  cugType?: string;
  isLoggedIn: boolean;
}) => ({
  checkout_page_book_button_click: {
    user_account_type: accountType && USER_ACCOUNT_TYPES[accountType],
    booking_id: bookingId,
    cug_type: cugType ? CUG_TYPE[cugType.toUpperCase()] : undefined,
    is_logged_in: isLoggedIn,
  },
});

export const buildAdditionalInfoLoginDealProcessMessage = ({
  accountType,
  bookingId,
  processState,
}: {
  accountType?: string;
  bookingId: string;
  processState: string;
}) => ({
  login_deal_process_checkout: {
    user_account_type: accountType && USER_ACCOUNT_TYPES[accountType],
    booking_id: bookingId,
    process_state: processState
      ? LOGIN_DEAL_PROCESS_STATE[processState.toUpperCase()]
      : undefined,
  },
});

export const buildAdditionalInfoMarketingOptInProcessMessage = ({
  bookingId,
  consentType,
  hotelId,
  isChecked,
  processState,
  searchCycleId,
  subscribedResult,
}: {
  processState: string;
  bookingId: string;
  consentType: string;
  isChecked: boolean;
  subscribedResult?: string;
  hotelId: string;
  searchCycleId?: string;
}) => ({
  marketing_opt_in_process: {
    marketing_process_state: processState
      ? MARKETING_PROCESS_STATES[processState.toUpperCase()]
      : MARKETING_PROCESS_STATES.UNSET_MARKETING_PROCESS_STATES,
    booking_id: bookingId,
    consent_type: consentType
      ? MARKETING_CONSENT_TYPES[consentType.toUpperCase()]
      : MARKETING_CONSENT_TYPES.UNSET_MARKETING_CONSENT_TYPES,
    is_checked: isChecked,
    subscribed_result: subscribedResult,
    hotel_id: parseInt(hotelId, 10),
    search_cycle_id: searchCycleId,
  },
});

export const buildAdditionalInfoSearchEmptyMessage = ({
  correlationId,
  entityId,
  entityName,
  entityType,
  requestId,
  searchCausedBy,
  searchId,
  searchStartFinishCycleId,
  specificHotelPriceStatus,
}: {
  correlationId: string;
  entityId?: string;
  entityName?: string;
  entityType?: string;
  requestId: string;
  searchId: string;
  specificHotelPriceStatus: string;
  searchCausedBy?: string;
  searchStartFinishCycleId?: string;
}) => ({
  search_empty_info: {
    entity_id: entityId && parseInt(entityId, 10),
    entity_type: entityType
      ? ENTITY_TYPE[entityType.toUpperCase()]
      : ENTITY_TYPE.UNSET_ENTITY_TYPE,
    entity_name: entityName,
    specific_hotel_price_status: specificHotelPriceStatus,
    search_caused_by: searchCausedBy
      ? SEARCH_CAUSE[searchCausedBy]
      : SEARCH_CAUSE.UNSET_SEARCH_CAUSE,
    search_start_finish_cycle_id: searchStartFinishCycleId,
    search_id: searchId,
    request_id: requestId,
    correlation_id: correlationId,
  },
});

export const buildAdditionalInfoSearchResultSelectedMessage = ({
  basePrice,
  correlationId,
  currency,
  dayviewCorrelationId,
  hasCoupon,
  hotelId,
  isCugDeal,
  isGoToHD,
  isLowest,
  isMainPrice,
  isMapPanelHotel,
  isRecommendHotel,
  isUpSortHotels,
  isViewedHotel,
  mainPriceArea,
  pageName,
  partnerId,
  position,
  price,
  searchId,
  taxFees,
}: {
  hasCoupon: boolean;
  hotelId: string;
  isCugDeal: boolean;
  pageName: string;
  position: number;
  searchId: string;
  isGoToHD: boolean;
  isMainPrice: boolean;
  isMapPanelHotel: boolean;
  isLowest: boolean;
  isViewedHotel: boolean;
  isRecommendHotel: boolean;
  isUpSortHotels: boolean;
  mainPriceArea: string;
  partnerId: string;
  price: number;
  correlationId: string;
  dayviewCorrelationId?: string;
  basePrice?: number;
  taxFees?: number;
  currency: string;
}) => ({
  search_result_selected: {
    has_cug_deal: isCugDeal,
    has_coupon: hasCoupon,
    is_up_sorted_hotels: isUpSortHotels,
    hotel_id: parseInt(hotelId, 10),
    is_go_to_hd: isGoToHD,
    is_main_price: isMainPrice,
    is_lowest: isLowest,
    is_recommend_hotel: isRecommendHotel,
    hotel_card_area: getHotelCardArea({
      isRecommendHotel,
      isMapPanelHotel,
      isViewedHotel,
    }),
    is_viewed_hotel: isViewedHotel,
    main_price_area: mainPriceArea,
    partner_id: partnerId,
    position,
    price:
      (price && { currency, amount: Math.round(price), unit: 'WHOLE' }) ||
      undefined,
    search_id: searchId,
    page_name: pageName,
    correlation_id: correlationId,
    day_view_correlation_id: dayviewCorrelationId,
    base_price:
      (basePrice && {
        currency,
        amount: Math.floor(basePrice),
        unit: 'WHOLE',
      }) ||
      undefined,
    tax_fees:
      (taxFees && { currency, amount: Math.floor(taxFees), unit: 'WHOLE' }) ||
      undefined,
  },
});

export const buildAdditionalInfoPricesLoadedMessage = ({
  allRatesCount,
  correlationId,
  currency,
  dBook,
  dayviewCorrelationId,
  hasCoupon,
  hasDbook,
  hasMeta,
  hotelId,
  isCugDeal,
  isFiltered,
  layoutType,
  pageName,
  partnerId,
  price,
  ratesWithImageCount,
  requestId,
  roomTypes,
  searchId,
  stay,
}: {
  allRatesCount: number;
  currency: string;
  dBook: boolean;
  hasCoupon: boolean;
  hasDbook: boolean;
  hasMeta?: boolean;
  hotelId: string;
  isCugDeal: boolean;
  pageName: string;
  partnerId: string;
  price: number;
  ratesWithImageCount: number;
  requestId: string;
  searchId: string;
  roomTypes?: string;
  layoutType?: string;
  isFiltered?: boolean;
  stay: Stay;
  correlationId?: string;
  dayviewCorrelationId?: string;
}) => ({
  prices_loaded: {
    has_cug_deal: isCugDeal,
    has_coupon: hasCoupon,
    has_dbook: hasDbook,
    has_meta: hasMeta,
    is_dbook: dBook,
    hotel_id: parseInt(hotelId, 10),
    partner_id: partnerId,
    price: { currency, amount: Math.round(price), unit: 'WHOLE' },
    search_id: searchId,
    page_name: pageName,
    request_id: requestId,
    room_types: roomTypes,
    is_filtered: isFiltered,
    layout_type: RATE_LAYOUT_TYPE[layoutType as string],
    all_rates_count: allRatesCount,
    rates_with_image_count: ratesWithImageCount,
    stay: getStayField(stay),
    correlation_id: correlationId,
    day_view_correlation_id: dayviewCorrelationId,
  },
});

export const buildAdditionalInfoSearchStartedMessage = ({
  entityID,
  entityName,
  isNewSearch,
  pageName,
  searchStartFinishCycleId,
}: {
  entityID: string;
  entityName: string;
  isNewSearch: boolean;
  pageName: string;
  searchStartFinishCycleId: string;
}) => ({
  search_started: {
    page_name: pageName,
    entity_id: parseInt(entityID, 10),
    entity_name: entityName,
    search_start_finish_cycle_id: searchStartFinishCycleId,
    is_new_search: isNewSearch,
  },
});

export const isLowestPrice = ({ allPricesInfo }: HotelCard) => {
  if (
    !allPricesInfo ||
    !allPricesInfo.prices ||
    !allPricesInfo.prices.length ||
    allPricesInfo.prices.length === 1
  ) {
    return true;
  }

  return allPricesInfo.prices[0].isLowest;
};

export const buildAdditionalInfoSearchResultLoadedMessage = ({
  correlationId,
  currency,
  hotel,
  searchStartFinishCycleId,
}: {
  searchStartFinishCycleId: string;
  hotel: HotelCard;
  currency: string;
  correlationId?: string;
}) => {
  const { cug, greatPricePercentage } = hotel.priceInfo;
  const cugType = (cug && cug.type && cug.type.toUpperCase()) || 'null';

  return {
    search_result_loaded: {
      search_start_finish_cycle_id: searchStartFinishCycleId,
      hotel_id: parseInt(hotel.id, 10),
      hotel_stars: hotel.numberOfStars || 0,
      price: hotel.priceInfo.price && {
        currency,
        amount: Math.round(hotel.priceInfo.price),
        unit: 'WHOLE',
      },
      partner_id: hotel.priceInfo.partnerId,
      has_cug_deal: !!cug,
      cug_deal_percentage: cug && cug.discountPercentage,
      cug_type: CUG_TYPE[cugType] ? CUG_TYPE[cugType] : CUG_TYPE.UNSET_CUG_TYPE,
      is_lowest: isLowestPrice(hotel),
      is_great_price: !!greatPricePercentage,
      great_price_percentage: greatPricePercentage,
      unique_correlation_id: correlationId,
    },
  };
};

export const buildAdditionalInfoSearchFinishedMessage = ({
  campaignId,
  checkIn,
  checkOut,
  correlationId,
  couponAmounts,
  couponHotels,
  couponRanks,
  couponType,
  cugAmounts,
  cugDeal,
  cugHotels,
  cugRanks,
  discountIncentive,
  discountPercentage,
  entityID,
  greatPricesCount,
  hasCoupon,
  hasUpSortHotels,
  pageName,
  requestId,
  savedHotelsCount,
  searchCausedBy,
  searchId,
  searchStartFinishCycleId,
}: {
  campaignId: string;
  checkIn: string;
  checkOut: string;
  couponAmounts: number[];
  couponHotels: number[];
  couponRanks: number[];
  couponType: string;
  cugAmounts: number[];
  cugDeal: boolean;
  cugHotels: number[];
  cugRanks: number[];
  discountIncentive: number;
  discountPercentage: number;
  entityID: string;
  greatPricesCount: number;
  hasCoupon: boolean;
  hasUpSortHotels: boolean;
  pageName: string;
  requestId: string;
  searchId: string;
  searchStartFinishCycleId: string;
  correlationId: string;
  searchCausedBy?: string;
  savedHotelsCount: number;
}) => ({
  search_finished: {
    page_name: pageName,
    entity_id: parseInt(entityID, 10),
    check_in_timestamp: getCommonsDateTime(checkIn, true),
    check_out_timestamp: getCommonsDateTime(checkOut, true),
    has_cug_deal: cugDeal,
    has_coupon: hasCoupon,
    has_up_sorted_hotels: hasUpSortHotels,
    coupon_type: couponType,
    discount_percentage: discountPercentage,
    discount_incentive: discountIncentive,
    coupon_hotels: couponHotels,
    coupon_ranks: couponRanks,
    coupon_amounts: couponAmounts,
    cug_hotels: cugHotels?.join(', ') ?? null,
    cug_ranks: cugRanks?.join(', ') ?? null,
    cug_amounts: cugAmounts?.join(', ') ?? null,
    campaign_id: campaignId,
    request_id: requestId,
    search_id: searchId,
    correlation_id: correlationId,
    search_start_finish_cycle_id: searchStartFinishCycleId,
    great_prices_count: greatPricesCount,
    search_cause: searchCausedBy
      ? SEARCH_CAUSE[searchCausedBy]
      : SEARCH_CAUSE.UNSET_SEARCH_CAUSE,
    saved_hotels_count: savedHotelsCount,
  },
});

export const buildAdditionalInfoNearbyMap = ({
  clickType,
  hotelId,
  isMapFullView,
  poiInfo,
}: {
  clickType: string;
  hotelId: number;
  isMapFullView: boolean;
  poiInfo: Nullable<{
    poi_id: number; // eslint-disable-line camelcase
    distance_in_meters: Nullable<number>; // eslint-disable-line camelcase
  }>;
}) => ({
  nearby_map: {
    is_map_full_view: isMapFullView,
    hotel_id: hotelId,
    click_type: clickType,
    poi_info: poiInfo,
  },
});

export const buildAdditionalInfoRecommendHotelsChipMessage = ({
  type,
}: {
  type: string;
}) => ({
  recommend_chip_info: {
    type: type ? type.toUpperCase() : 'UNSET_CHIP_TYPE',
  },
});

export const buildAdditionalInfoSearchSyncMapCheckBoxClickMessage = ({
  isSync,
}: {
  isSync: boolean;
}) => ({
  search_sync_map_checkbox_click: {
    is_sync: isSync,
  },
});

export const buildAdditionalInfoDetailRatesFilterClick = (
  filterValue: string[],
) => ({
  detail_rates_filter_click: Object.keys(RATES_FILTER_TYPE).reduce(
    (acc: any, curr: any) => {
      acc[RATES_FILTER_TYPE[curr]] = !!filterValue[curr];
      return acc;
    },
    {},
  ),
});

export const buildAdditionalInfoBaseRoomTypeFilterSelected = (
  roomType: string,
  filterStatus: boolean,
) => ({
  base_room_type_filter_info: {
    base_room_type: roomType && BASE_ROOM_TYPE[roomType.toUpperCase()],
    is_selected: filterStatus,
  },
});

export const buildAdditionalInfoViewButtonClicked = (clickArea: string) => ({
  view_button_clicked: {
    click_area: clickArea,
  },
});

export const buildAdditionalInfoAutoSuggestPoiClick = ({
  cityId,
  poiId,
}: {
  cityId: string;
  poiId: string;
}) => ({
  auto_suggest_poi_click: { city_id: cityId, poi_id: poiId },
});

export const buildAdditionalInfoAutoSuggestPois = (
  suggestion: AutosuggestSuggestion,
) => {
  let poi;
  const poiList = AUTO_SUGGEST_POI_LIST.reduce(
    (acc: any, curr: string, index: number) => {
      poi = (suggestion.pois && suggestion.pois[index]) || {};
      acc[curr] = poi.entityId || '';
      return acc;
    },
    {},
  );
  return {
    auto_suggest_pois: {
      city_id: suggestion.entityId,
      ...poiList,
    },
  };
};

export const buildAdditionInfoBookingProcessState = ({
  bookingId,
  errorCode,
  hotelId,
  messageState,
  policyChangedType,
  priceChangedInfo,
  processState,
  resultStatus,
  searchCycleId,
}: {
  bookingId?: string;
  processState: string;
  messageState?: string;
  hotelId: string;
  priceChangedInfo?: PriceChangedInfo;
  policyChangedType?: string;
  searchCycleId?: string;
  errorCode?: string;
  resultStatus?: string;
}) => ({
  booking_process_state_info: {
    hotel_id: parseInt(hotelId, 10),
    booking_id: bookingId,
    booking_process_state: BOOKING_PROCESS_STATE[processState],
    message_state: messageState ? MESSAGE_STATE[messageState] : undefined,
    price_changed_info: priceChangedInfo
      ? {
          prev_price: {
            currency: priceChangedInfo.currency,
            amount: Math.round(priceChangedInfo.expectedPrice),
            unit: 'WHOLE',
          },
          changed_price: {
            currency: priceChangedInfo.currency,
            amount: Math.round(priceChangedInfo.diffAmount),
            unit: 'WHOLE',
          },
          price_changed_type: PRICE_CHANGED_TYPE[priceChangedInfo.type],
        }
      : undefined,
    policy_changed_type: policyChangedType
      ? POLICY_CHANGED_TYPE[policyChangedType.toUpperCase()]
      : POLICY_CHANGED_TYPE.UNSET_POLICY_CHANGED_TYPE,
    search_cycle_id: searchCycleId,
    payment_error_code: errorCode,
    payment_result_status: resultStatus
      ? PAYMENT_RESULT_STATUS[resultStatus]
      : PAYMENT_RESULT_STATUS.UNSET_PAYMENT_RESULT_STATUS,
  },
});

export const buildAdditionInfoUserActionInfo = ({
  action,
  bookingId,
  hotelId,
  sceneName,
}: {
  action: string;
  bookingId: string;
  hotelId: string;
  sceneName: string;
}) => ({
  user_action_info: {
    hotel_id: parseInt(hotelId, 10),
    booking_id: bookingId,
    action: ACTION_IN_SCENE[action],
    action_area: SCENE_NAMES[sceneName],
  },
});

export const buildAdditionInfoBookingPerformed = ({
  bookingId,
  bookingStatus,
  cug,
  currency,
  hotelId,
  partnerId,
  price,
  redirectId,
  resultStatus,
  roomRateId,
  searchId,
  stay,
}: {
  bookingId: string;
  bookingStatus: string;
  currency: string;
  hotelId: string;
  partnerId: string;
  price?: number;
  roomRateId?: string;
  searchId: string;
  cug?: CugPrice;
  redirectId: string;
  stay: Stay;
  resultStatus?: string;
}) => {
  const cugType = (cug && cug.type && cug.type.toUpperCase()) || 'null';

  return {
    booking_performed_info: {
      booking_id: bookingId,
      booking_status: BOOKING_STATUS[bookingStatus],
      hotel_id: parseInt(hotelId, 10),
      partner_id: partnerId,
      price: price && {
        currency,
        amount: Math.round(price),
        unit: 'WHOLE',
      },
      room_rate_id: roomRateId,
      search_id: searchId,
      cug_type: CUG_TYPE[cugType] ? CUG_TYPE[cugType] : CUG_TYPE.UNSET_CUG_TYPE,
      cug_deal_percentage: cug && cug.discountPercentage,
      redirect_id: redirectId,
      stay: getStayField(stay),
      payment_result_status: resultStatus
        ? PAYMENT_RESULT_STATUS[resultStatus]
        : PAYMENT_RESULT_STATUS.UNSET_PAYMENT_RESULT_STATUS,
    },
  };
};

export const buildAdditionalInfoMapActiveDayView = (clickArea: string) => ({
  map_active_day_view: {
    click_area: clickArea,
  },
});

export const buildAdditionalInfoPoiMenuSelectDayView = ({
  clickArea,
  poiId,
  poiType,
}: {
  clickArea: string;
  poiId: string;
  poiType: string;
}) => ({
  poi_menu_select_day_view: {
    poi_id: poiId,
    poi_type: poiType,
    click_area: clickArea,
  },
});

export const buildAdditionalInfoViewedHotelsDataLoaded = ({
  hasSearchedCity,
  pageType,
}: {
  hasSearchedCity: boolean;
  pageType: string;
}) => ({
  viewed_hotels_data_loaded: {
    page_type: pageType,
    has_searched_city: hasSearchedCity,
  },
});

export const buildAdditionalInfoHotelPriceStatusMessage = ({
  hasRate,
  hotelId,
}: {
  hasRate: boolean;
  hotelId: string;
}) => ({
  hotel_prices_status: {
    hotel_id: parseInt(hotelId, 10),
    has_price: hasRate,
  },
});

export const buildAdditionalInfoUnifiedPaymentPlatformMessage = ({
  action,
  bookingId,
  partnerId,
  retry,
  status,
}: {
  action: string;
  bookingId: string;
  partnerId: string;
  retry: boolean;
  status: string;
}) => ({
  unified_payment_integration_status: {
    current_step: UNIFIED_INTEGRATION_STEP_TYPE[action.toUpperCase()],
    status: ACTION_STATUS_YPE[status],
    partner_id: PARTNER_ID[partnerId.toUpperCase()],
    booking_id: bookingId,
    is_retry: retry,
  },
});

export const buildAdditionalInfoPreCheckState = ({
  cugType,
  currency,
  expectedPrice,
  hotelId,
  isLoggedIn,
  preCheckState,
  prevTrackingId,
  priceConsistencyCheck,
  rateId,
  redirectId,
  roomId,
  searchCycleId,
  trackingId,
}: {
  cugType: Maybe<string>;
  hotelId: string;
  isLoggedIn: boolean;
  preCheckState: string;
  prevTrackingId: string;
  priceConsistencyCheck: boolean;
  rateId: string;
  redirectId: string;
  roomId: string;
  searchCycleId: string;
  trackingId: string;
  expectedPrice?: number;
  currency: string;
}) => ({
  checkout_form_pre_check_state: {
    cug_type: cugType ? CUG_TYPE[cugType.toUpperCase()] : undefined,
    hotel_id: parseInt(hotelId, 10),
    pre_check_state: MESSAGE_STATE[preCheckState],
    room_id: roomId,
    rate_id: rateId,
    prev_tracking_id: prevTrackingId,
    is_price_consistency_check: priceConsistencyCheck,
    tracking_id: trackingId,
    is_logged_in: isLoggedIn,
    search_cycle_id: searchCycleId,
    redirect_id: redirectId,
    price: expectedPrice && {
      currency,
      amount: Math.round(expectedPrice),
      unit: 'WHOLE',
    },
  },
});

export const buildAdditionalInfoDealsCitiesFilterClick = ({
  cityId,
  clickArea,
  groupKey,
  isBestDeal,
}: {
  cityId: string;
  clickArea: string;
  groupKey: string;
  isBestDeal: boolean;
}) => ({
  deals_cities_filter_click: {
    city_entity_id: parseInt(cityId, 10),
    group_key: groupKey,
    click_area: clickArea,
    is_best_deal: isBestDeal,
  },
});

export const buildAdditionalInfoSearchConditionChanged = ({
  adultChanged,
  changedCondition,
  childrenAgesChanged,
  hotelId,
  isSearchWithChildren,
  roomNumChanged,
  rooms,
}: {
  adultChanged: Maybe<boolean>;
  changedCondition: string;
  childrenAgesChanged: Maybe<boolean>;
  hotelId?: string;
  isSearchWithChildren: boolean;
  roomNumChanged: Maybe<boolean>;
  rooms: number;
}) => ({
  search_condition_changed: {
    hotel_id: hotelId && parseInt(hotelId, 10),
    changed_condition: changedCondition,
    rooms,
    is_search_with_children: isSearchWithChildren,
    guest_rooms_changed_info:
      changedCondition === CHANGED_CONDITION.GUEST_OR_ROOMS
        ? {
            has_number_of_adults_changed: adultChanged,
            has_number_of_children_changed: childrenAgesChanged,
            has_number_of_rooms_changed: roomNumChanged,
          }
        : undefined,
  },
});

export const buildAdditionalInfoReviewFilterClicked = ({
  type,
  value,
}: {
  type: string;
  value: Maybe<string>;
}) => ({
  review_filter_clicked: {
    type: REVIEW_FILTER_TYPE_MAPPING[type] || FILTER_TYPE.UNSET_FILTER_TYPE,
    value: value || '',
  },
});

export const buildAdditionalInfoCrossLinkClicked = ({
  entityId,
  section,
}: {
  entityId: string;
  section: string;
}) => ({
  cross_link_clicked: {
    city_entity_id: parseInt(entityId, 10),
    section: SECTION_TYPE[section] || SECTION_TYPE.UNSET_SECTION_TYPE,
  },
});

export const buildAdditionalInfoTranslationButtonClicked = ({
  clickArea,
  sourceLocale,
  targetLocale,
}: {
  clickArea: string;
  sourceLocale: string;
  targetLocale: string;
}) => ({
  translation_button_clicked: {
    click_area: CLICK_AREA[clickArea] || CLICK_AREA.UNSET_CLICK_AREA,
    source_locale: sourceLocale,
    target_locale: targetLocale,
  },
});

export const buildAdditionalInfoPaginationClicked = ({
  pageNum,
}: {
  pageNum: number;
}) => ({
  pagination_clicked: {
    page_num: pageNum,
  },
});

export const buildAdditionalInfoCheckoutMultiRoomsAction = ({
  actionType,
}: {
  actionType: string;
}) => ({
  checkout_multi_rooms_action: {
    action_type:
      MULTI_ROOMS_ACTION_TYPE[actionType] ||
      MULTI_ROOMS_ACTION_TYPE.UNSET_MULTI_ROOMS_ACTION_TYPE,
  },
});

export const buildAdditionalInfoMetaPushLoginProcess = ({
  correlationId,
  hotelId,
  partnerId,
  processState,
  requestId,
}: {
  processState: string;
  hotelId?: string;
  correlationId?: Maybe<string>;
  partnerId?: string;
  requestId?: Maybe<string>;
}) => ({
  meta_push_login_process: {
    process_state:
      META_PUSH_LOGIN_ACTION_TYPE[processState] ||
      META_PUSH_LOGIN_ACTION_TYPE.UNSET_PUSH_LOGIN_PROCESS_STATES,
    partner_id: partnerId,
    hotel_id: hotelId,
    correlation_id: correlationId,
    request_id: requestId,
  },
});

export const buildAdditionalInfoSaveStatusChanged = ({
  actionId,
  clickArea,
  correlationId,
  hotelId,
  isLoggedIn,
  saveStatus,
  stay,
}: {
  clickArea: string;
  hotelId: string;
  correlationId?: string;
  isLoggedIn: boolean;
  stay: Stay;
  saveStatus: string;
  actionId: string;
}) => ({
  save_status_changed: {
    click_area: clickArea,
    hotel_id: hotelId,
    correlation_id: correlationId,
    is_logged_in: isLoggedIn,
    stay: getStayField(stay),
    action_id: actionId,
    save_status:
      SAVE_STATUS_TYPE[saveStatus] || SAVE_STATUS_TYPE.UNSET_SAVE_HOTEL_STATUS,
  },
});

export const buildAdditionalInfoSaveNotificationClicked = ({
  clickArea,
  hotelId,
}: {
  clickArea: string;
  hotelId: string;
}) => ({
  save_notification_clicked: {
    click_area: clickArea,
    hotel_id: hotelId,
  },
});
