import GLOBAL from 'saddlebag-browser/src/GLOBAL';

export const isTestEnv = () => {
  const $window = GLOBAL.getWindow();
  return $window.location.hostname === 'localhost';
};

export const isProdEnv = () => process.env.NODE_ENV === 'production';

// @ts-expect-error I don't known why i have declare 'staging' in client/src/types/global.d.ts but not work
export const isStagingEnv = () => process.env.NODE_ENV === 'staging';
