/* eslint-disable no-console */

import observer from 'saddlebag-observer';

import type { ObserverClient as ObserverClientType } from '../types';

type Props = {
  destinations: Array<'observer' | 'console' | 'event'>;
};

const ObserverClient = ({ destinations }: Props): ObserverClientType => ({
  track: (event, data) => {
    if (destinations.find((dest) => dest === 'observer')) {
      observer.publish(event, data);
    }

    if (destinations.find((dest) => dest === 'console')) {
      console.info(event, data);
    }

    if (destinations.find((dest) => dest === 'event')) {
      try {
        const tracker = new Event(event);
        Object.keys(data).forEach((key) => {
          // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Event'.
          tracker[key] = data[key];
        });
        window.dispatchEvent(tracker);
      } catch (e) {
        // this feature is only used for the acceptance tests and running on the headless Chrome
        // to check weather the mixpanel/mini events are fired, but IE11 doesn't support it
      }
    }
  },
});

export default ObserverClient;
