import { FunnelEventsTracking } from 'saddlebag-user-tracking-events';

import logger from '../../services/logger';
import { isProdEnv } from '../../utils/env';

import type {
  UserSearched,
  OneOfMessage,
  FunnelEventsClient as FunnelEventsClientType,
} from '../types';

export const coordToGeoMeasurement = (coordinate?: number) => {
  if (!coordinate) return null;
  const coordinateString = coordinate.toString();

  const splitResult = coordinateString.split('.');

  let precision;
  if (splitResult.length > 1) precision = splitResult[1].length;
  else precision = 0;
  const amount = Number(coordinate * 10 ** precision);
  return {
    precision,
    amount,
  };
};

type Tracker = {
  track: (fullSchemaName: string, message: OneOfMessage) => void;
};

class FunnelEventsClient implements FunnelEventsClientType {
  tracker: Tracker;

  constructor(tracker?: Tracker) {
    const env = isProdEnv() ? 'public' : 'public-sandbox';
    this.tracker = tracker || new FunnelEventsTracking(env, 'hotels-website');
  }

  trackSearch({
    adm1,
    adm2,
    city,
    correlationId,
    entityId,
    lat,
    lon,
    name,
    nation,
    requestId,
    searchStartFinishCycleId,
    skyscannerNodeCode,
    stay,
  }: UserSearched) {
    try {
      this.tracker.track(isProdEnv() ? 'clients.Search' : 'sandbox.Search', {
        business_domain: 'HOTEL',
        destination: {
          location_attribute: {
            location_attribute_encoding: skyscannerNodeCode
              ? 'SS_GEO1_ID'
              : 'DDB_ID',
            location_id: skyscannerNodeCode || entityId,
            location_name: name,
            // location_descriptor_level: type,
            location_descriptors: {
              NATION: nation,
              CITY: city,
              ADM1: adm1,
              ADM2: adm2,
            },
          },
          geo: {
            latitude_int: coordToGeoMeasurement(lat),
            longitude_int: coordToGeoMeasurement(lon),
          },
        },
        start_date: {
          unix_time_millis: stay.checkIn.getTime(),
          date_time_kind: 'DAY',
        },
        end_date: {
          unix_time_millis: stay.checkOut.getTime(),
          date_time_kind: 'DAY',
        },
        hotel_search: {
          adults: stay.numberOfAdults,
          correlation_id: correlationId,
          rooms: stay.numberOfRooms,
          request_id: requestId,
          search_start_finish_cycle_id: searchStartFinishCycleId,
        },
      });
    } catch (err) {
      logger.error('Failed to track trusted funnel event', {}, err);
    }
  }
}

export default FunnelEventsClient;
