import type { GaClient as GaClientType, ObserverClient } from '../types';

const GA_TRACK_EVENT = 'tracking-event';

class GaClient implements GaClientType {
  observerClient: ObserverClient;

  constructor(observerClient: ObserverClient) {
    this.observerClient = observerClient;
  }

  track = (category: string, action: string, label: string) => {
    const event = {
      ga: [
        {
          category,
          action,
          label,
        },
      ],
    };
    this.observerClient.track(GA_TRACK_EVENT, event);
  };
}

export default GaClient;
