import addDays from 'date-fns/addDays';

import { formatDate } from '../../utils/machine-date-utils';
import {
  isStayLengthValid,
  areStayDatesValid,
  areRoomsAndAdultsValid,
  defaultGuestDates,
  defaultGuestsAndRooms,
  defaultChildren,
  areChildrenValid,
} from '../guest-stay';

import {
  getQueryParam,
  getDateParam,
  getIntParam,
  getStringParam,
} from './url';

const getStayDatesFromUrl = (url: string) => {
  const stayDates = {
    checkIn: getDateParam(url, 'checkin') || getDateParam(url, 'sd'),
    checkOut: getDateParam(url, 'checkout') || getDateParam(url, 'ed'),
  };

  const { checkIn, checkOut } = stayDates;
  const isFromFlight = !!getQueryParam(url, 's-f_iplace');

  if (
    checkIn &&
    (!checkOut ||
      (checkOut && isFromFlight && !isStayLengthValid(checkIn, checkOut, 5)))
  ) {
    Object.assign(stayDates, { checkOut: addDays(checkIn, 1) });
  }

  if (!areStayDatesValid(stayDates)) {
    return null;
  }

  return stayDates;
};

const getGuestsAndRoomsFromUrl = (url: string) => {
  const guestsAndRooms = {
    numberOfAdults: getIntParam(url, 'adults') || getIntParam(url, 'na') || 2,
    numberOfRooms: getIntParam(url, 'rooms') || getIntParam(url, 'nr') || 1,
  };

  if (!areRoomsAndAdultsValid(guestsAndRooms)) {
    return null;
  }
  return guestsAndRooms;
};

const getChildrenFromUrl = (url: string) => {
  const childrenAges =
    getStringParam(decodeURIComponent(url), 'children_ages') ||
    getStringParam(decodeURIComponent(url), 'nc');
  const stay: { childrenAges: string[] } = {
    childrenAges: [],
  };
  if (childrenAges) {
    stay.childrenAges = childrenAges.split(',');
  }

  if (!areChildrenValid(stay)) {
    return null;
  }
  return stay;
};

const getStayFromUrl = (url: string) => {
  const stayDates = getStayDatesFromUrl(url);
  if (!stayDates) {
    throw new Error('INVALID_STAY_DATES');
  }

  const guestsAndRooms = getGuestsAndRoomsFromUrl(url);
  if (!guestsAndRooms) {
    throw new Error('INVALID_GUESTS_AND_ROOMS');
  }

  const childrenAges = getChildrenFromUrl(url);
  if (!childrenAges) {
    throw new Error('INVALID_CHILDREN_AGE');
  }

  return { ...stayDates, ...guestsAndRooms, ...childrenAges };
};

const getDefaultedStayFromUrl = (url: string, offset = 7) => {
  const stayDates = getStayDatesFromUrl(url) || defaultGuestDates(offset);
  const guestsAndRooms =
    getGuestsAndRoomsFromUrl(url) || defaultGuestsAndRooms();
  const childrenAges = getChildrenFromUrl(url) || defaultChildren();
  return { ...stayDates, ...guestsAndRooms, ...childrenAges };
};

const getDefaultedStay = (offset = 0) => {
  const stayDates = defaultGuestDates(offset);
  const guestsAndRooms = defaultGuestsAndRooms();
  const childrenAges = defaultChildren();

  return {
    ...guestsAndRooms,
    ...childrenAges,
    checkIn: formatDate(stayDates.checkIn),
    checkOut: formatDate(stayDates.checkOut),
  };
};

export { getStayFromUrl, getDefaultedStayFromUrl, getDefaultedStay };
