import type { ObjectMapping } from 'common/src/types/utils';

const objValues = (obj: ObjectMapping) =>
  Object.keys(obj).map((key) => obj[key]);

export default objValues;

type StringObj = {
  [key: string]: any;
};

export const objectKeyToValueArray = (obj: StringObj) =>
  Object.keys(obj).map((key) => {
    const result: StringObj = {};
    result[key] = obj[key];
    return result;
  });
