import { store } from '@skyscanner-internal/save-to-list-client';

import type { Device } from 'common/src/types/user-context';
import type { Stay, Maybe, Nullable } from 'common/src/types/utils';

import getDeviceType from '../../components/get-device-type';
import logger from '../../services/logger';
import { getCurrentQueryParam } from '../../services/url/url';
import GaClient from '../ga-client';
import {
  ACTION_TYPE,
  BOOKING_STATUS_MAPPING,
  PAYMENT_RESULT_STATUS_MAPPING,
} from '../minievents/constants';
import {
  buildAdditionalInfoPageLoadMessage,
  buildAdditionalInfoSearchStartedMessage,
  buildAdditionalInfoSearchFinishedMessage,
  buildAdditionalInfoSearchResultSelectedMessage,
  buildAdditionalInfoPricesLoadedMessage,
  buildDayViewFiltersUsedMessage,
  buildDayViewSortUsedMessage,
  buildAdditionalInfoSearchResultLoadedMessage,
  buildAdditionInfoBookingProcessState,
  buildAdditionInfoBookingPerformed,
  PRICES_LOADED_TYPES,
  buildAdditionalInfoHotelPriceStatusMessage,
  buildAdditionalInfoUnifiedPaymentPlatformMessage,
  buildAdditionalInfoPreCheckState,
  buildAdditionalInfoDealsCitiesFilterClick,
  buildDayViewHotelCardClickedMessage,
  buildAdditionalInfoSearchEmptyMessage,
} from '../minievents/hotels-action';
import { AUTOSUGGEST_EVENTS } from '../newrelic-events/autosuggest-events';

import FunnelEventsClient from './funnel-events-client';
import StatsDClient from './statsd-client';

import type {
  HotelCard,
  HotelCards,
  CugPrice,
  TaxAndFees,
} from '../../types/hotel-card';
import type {
  Metrics,
  StatsDClient as StatsDClientType,
  StatsDProps,
  GaClient as GaClientType,
  ElementEventTracker,
  OneOfTag,
  ObserverClient,
  FunnelEventsClient as FunnelEventsClientType,
  UserSearched,
  PriceChangedInfo,
} from '../types';
import type SavedItemsMatching from '@skyscanner-internal/save-to-list-client/models/SavedItemsMatching';

type Props = {
  statsD: StatsDProps;
  deviceInfo: Device;
  pageName: string;
  region: string;
  trafficSource: string;
  elementEventTracker: ElementEventTracker;
  observerClient: ObserverClient;
};

type PaymentProps = {
  partnerId: string;
  type?: string;
};

type ThreeDS2PaymentProps = {
  partnerId: string;
  market: string;
  currency: string;
};

type BabysharkPrecheckProps = {
  env: string;
  issuercc?: string;
  isSupported?: boolean;
} & ThreeDS2PaymentProps;

type CreateCardProps = {
  statusCode: string;
};

type SearchFilters = {
  [name: string]: {
    [value: string]: number | boolean;
  };
};

/**
 * Application Metrics
 * Stable Events that can be logged to multiple sources
 * */

class ApplicationMetrics implements Metrics {
  ga: GaClientType;

  statsd: StatsDClientType;

  funnelEvents: FunnelEventsClientType;

  deviceInfo: Device;

  pageName: string;

  region: string;

  trafficSource: string;

  elementEventTracker: ElementEventTracker;

  constructor({
    deviceInfo,
    elementEventTracker,
    observerClient,
    pageName,
    region,
    statsD,
    trafficSource,
  }: Props) {
    this.statsd = new StatsDClient(statsD);
    this.funnelEvents = new FunnelEventsClient();
    this.pageName = pageName;
    this.region = region;
    this.ga = new GaClient(observerClient);
    this.deviceInfo = deviceInfo;
    this.trafficSource = trafficSource;
    this.elementEventTracker = elementEventTracker;
  }

  // Indicates that the user has done a new search by changing the destination,
  // dates, adults or rooms. Filtering or sorting search results is not included.
  userSearched({
    adm1,
    adm2,
    city,
    correlationId,
    entityId,
    lat,
    lon,
    name,
    nation,
    requestId,
    searchStartFinishCycleId,
    skyscannerNodeCode,
    stay,
  }: UserSearched) {
    this.funnelEvents.trackSearch({
      entityId,
      skyscannerNodeCode,
      stay,
      lat,
      lon,
      name,
      nation,
      city,
      adm1,
      adm2,
      requestId,
      correlationId,
      searchStartFinishCycleId,
    });
  }

  pageLoadStarted() {
    this.statsd.increment('pageLoad.start', 1, {
      pageName: this.pageName,
      region: this.region,
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
    });
  }

  pageLoadSuccess({
    fallbackTranslations,
    isFromFlightDBookEmail,
    isLoggedIn,
    isSpider,
    market,
    stay,
  }: {
    market: string;
    isFromFlightDBookEmail: boolean;
    isLoggedIn: boolean;
    fallbackTranslations: boolean;
    isSpider: boolean;
    stay: Stay;
  }) {
    const eventFieldss = {
      market,
      fallbackTranslations,
      isSpider,
      pageName: this.pageName,
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
    };
    this.statsd.increment('pageLoad.success', 1, eventFieldss);
    logger.event('pageLoad.success', {
      ...eventFieldss,
      isRobot: `${this.deviceInfo.isRobot}`,
      fallbackTranslations: `${fallbackTranslations}`,
      isSpider: `${isSpider}`,
    });

    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE[`${this.pageName}_page_load`.toUpperCase()],
      buildAdditionalInfoPageLoadMessage({
        pageName: this.pageName,
        region: this.region,
        isLoggedIn,
        stay,
      }),
    );

    this.ga.track(
      this.pageName,
      'pageLoadSuccess',
      JSON.stringify({
        isFromFlightDBookEmail,
      }),
    );
  }

  pageLoadFailure() {
    this.statsd.increment('pageLoad.failure', 1, {
      pageName: this.pageName,
      region: this.region,
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
    });
  }

  // Indicates that the Search Button inside Search Controls has been submitted
  searchSubmitted() {
    this.statsd.increment('search.submitted', 1, { pageName: this.pageName });
  }

  // Indicates that the Search Button inside Search Controls has been clicked
  searchClicked() {
    this.statsd.increment('search.clicked', 1, { pageName: this.pageName });
  }

  searchValidationFailed() {
    this.statsd.increment('search.validationFailed', 1, {
      pageName: this.pageName,
    });
  }

  // Indicates that a new request for search results has been made. This can
  // happen because a user has done a new search or because filter or sort changed.
  searchCycleStarted({
    entityId,
    filters,
    isNewSearch,
    name,
    searchStartFinishCycleId,
  }: {
    entityId: string;
    name: string;
    searchStartFinishCycleId: string;
    isNewSearch: boolean;
    filters: SearchFilters;
  }) {
    this.statsd.increment('search.start', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });

    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.SEARCH_STARTED,
      buildAdditionalInfoSearchStartedMessage({
        pageName: this.pageName,
        entityID: entityId,
        entityName: name,
        searchStartFinishCycleId,
        isNewSearch,
      }),
    );

    const isFilterDiscountChecked = filters?.discount_types?.cug === true;
    if (isFilterDiscountChecked) {
      this.elementEventTracker.trackExperimentChokepoint(
        'hotels_dayview_show_discounts_filter',
      );
    }
  }

  hotelsHunterFailure() {
    this.statsd.increment('hotelsHunter.failure');
  }

  hotelsHunterEmpty() {
    this.statsd.increment('hotelsHunter.empty');
  }

  hotelsHunterSuccess(timeTaken: number, tags = {}) {
    this.statsd.increment('hotelsHunter.success');
    this.statsd.timing('hotelsHunter.success', timeTaken, tags);
  }

  hotelsHunterCycleStarted() {
    this.statsd.increment('hotelsHunter.start');
  }

  searchSuccess(
    timeTaken: number,
    {
      campaignId,
      checkIn,
      checkOut,
      correlationId,
      couponAmounts,
      couponHotels,
      couponRanks,
      couponType,
      cugAmounts,
      cugDeal,
      cugHotels,
      cugRanks,
      discountIncentive,
      discountPercentage,
      entityId,
      greatPricesCount,
      hasCoupon,
      requestId,
      searchCausedBy,
      searchId,
      searchStartFinishCycleId,
    }: {
      entityId: string;
      checkIn: string;
      checkOut: string;
      cugDeal: boolean;
      hasCoupon: boolean;
      couponType: string;
      discountPercentage: number;
      discountIncentive: number;
      couponHotels: number[];
      couponRanks: number[];
      couponAmounts: number[];
      cugHotels: number[];
      cugRanks: number[];
      cugAmounts: number[];
      campaignId: string;
      requestId: string;
      searchId: string;
      searchStartFinishCycleId: string;
      greatPricesCount: number;
      correlationId: string;
      searchCausedBy?: string;
    },
    tags = {},
  ) {
    const savedItems = store?.getSavedItems() as SavedItemsMatching;
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.SEARCH_FINISHED,
      buildAdditionalInfoSearchFinishedMessage({
        pageName: this.pageName,
        entityID: entityId,
        checkIn,
        checkOut,
        cugDeal,
        hasCoupon,
        hasUpSortHotels: !!getCurrentQueryParam('upsort_hotels'),
        couponType,
        discountPercentage,
        discountIncentive,
        couponHotels,
        couponRanks,
        couponAmounts,
        cugAmounts,
        cugHotels,
        cugRanks,
        campaignId,
        requestId,
        searchId,
        searchStartFinishCycleId,
        greatPricesCount,
        correlationId,
        searchCausedBy,
        savedHotelsCount: savedItems?.hotels.length || 0,
      }),
    );

    this.statsd.increment('search.success');
    this.statsd.timing('search.success', timeTaken, tags);
    this.elementEventTracker.trackExperimentChokepoint(
      'hotel_day_view_search_results_rendered',
    );
  }

  fastSearchSuccess(timeTaken: number, tags = {}) {
    this.statsd.increment('search.fast-success');
    this.statsd.timing('search.fast-success', timeTaken, tags);
  }

  searchEmpty() {
    this.statsd.increment('search.empty', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });
  }

  traceSearchEmpty({
    correlationId,
    entityId,
    entityName,
    entityType,
    requestId,
    searchCausedBy,
    searchCycleId,
    searchStartFinishCycleId,
    specificHotelPriceStatus,
  }: {
    correlationId: string;
    entityId?: string;
    entityName?: string;
    entityType?: string;
    requestId: string;
    searchCycleId: string;
    specificHotelPriceStatus: string;
    searchCausedBy?: string;
    searchStartFinishCycleId?: string;
  }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.SEARCH_EMPTY,
      buildAdditionalInfoSearchEmptyMessage({
        correlationId,
        entityId,
        entityName,
        entityType,
        requestId,
        searchCausedBy,
        searchId: searchCycleId,
        specificHotelPriceStatus,
        searchStartFinishCycleId,
      }),
    );
  }

  searchFailure() {
    this.statsd.increment('search.failure', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });
  }

  topSearchResultsLoaded({
    correlationId,
    currency,
    hotels,
    searchStartFinishCycleId,
  }: {
    searchStartFinishCycleId: string;
    hotels: HotelCards;
    currency: string;
    correlationId?: string;
  }) {
    hotels.forEach((hotel: HotelCard) => {
      this.elementEventTracker.trackHotelsAction(
        ACTION_TYPE.SEARCH_RESULT_LOADED,
        buildAdditionalInfoSearchResultLoadedMessage({
          searchStartFinishCycleId,
          hotel,
          currency,
          correlationId,
        }),
      );
    });
  }

  autoSuggestSearch({
    duration,
    locale,
    status,
  }: {
    locale: string;
    status: number;
    duration: number;
  }) {
    this.statsd.increment('autoSuggest.search', 1, {
      pageName: this.pageName,
      locale,
      status,
    });
    logger.event(AUTOSUGGEST_EVENTS.AUTOSUGGEST_SEARCH, {
      duration,
      status,
    });
  }

  searchResultSelected({
    basePrice,
    correlationId,
    cugDeal,
    currency,
    dayviewCorrelationId,
    hasCoupon,
    hotelId,
    isGoToHD,
    isLowest,
    isMainPrice,
    isMapPanelHotel = false,
    isRecommendHotel = false,
    isViewedHotel = false,
    mainPriceArea,
    partnerId,
    position,
    price,
    searchId,
    taxesAndFees,
  }: {
    hotelId: string;
    position: number;
    price: number;
    partnerId: string;
    cugDeal: boolean;
    hasCoupon: boolean;
    searchId: string;
    currency: string;
    isMainPrice: boolean;
    isGoToHD: boolean;
    mainPriceArea: string;
    isRecommendHotel?: boolean;
    isViewedHotel?: boolean;
    isMapPanelHotel?: boolean;
    isLowest: boolean;
    correlationId: string;
    dayviewCorrelationId?: string;
    basePrice?: number;
    taxesAndFees?: TaxAndFees;
  }) {
    const isUpSortHotels =
      getCurrentQueryParam('upsort_hotels')?.split(',')[0] === hotelId ?? false;
    this.statsd.increment('searchResultSelected', 1, {
      pageName: this.pageName,
    });

    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.SEARCH_RESULT_SELECTED,
      buildAdditionalInfoSearchResultSelectedMessage({
        isCugDeal: cugDeal,
        isLowest,
        hasCoupon,
        isUpSortHotels,
        hotelId,
        isGoToHD,
        isMainPrice,
        isRecommendHotel,
        isViewedHotel,
        isMapPanelHotel,
        mainPriceArea,
        partnerId,
        position,
        currency,
        price,
        searchId,
        pageName: this.pageName,
        correlationId,
        dayviewCorrelationId,
        basePrice,
        taxFees: taxesAndFees?.reduce((acc, cur) => acc + cur.total, 0),
      }),
    );
  }

  pricesStarted() {
    this.statsd.increment('prices.start', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });
  }

  pricesSuccess(
    timeTaken: number,
    {
      allRatesCount,
      correlationId,
      cugDeal,
      currency,
      dBook,
      dayviewCorrelationId,
      forMobileControl,
      hasCoupon,
      hasDbook,
      hasMeta,
      hotelId,
      isFiltered,
      layoutType,
      partnerId,
      price,
      ratesWithImageCount,
      requestId,
      roomTypes,
      searchId,
      stay,
    }: {
      cugDeal: boolean;
      hasCoupon: boolean;
      hasDbook: boolean;
      hasMeta?: boolean;
      dBook: boolean;
      price: number;
      hotelId: string;
      searchId: string;
      partnerId: string;
      requestId: string;
      roomTypes?: string;
      layoutType?: string;
      isFiltered?: boolean;
      forMobileControl?: boolean;
      currency: string;
      allRatesCount: number;
      ratesWithImageCount: number;
      stay: Stay;
      correlationId?: string;
      dayviewCorrelationId?: string;
    },
  ) {
    this.statsd.increment('prices.success');
    this.statsd.timing('prices.success', timeTaken);
    this.priceStatus({ hasRate: true, hotelId });

    if (forMobileControl)
      this.priceLoaded({
        cugDeal,
        dBook,
        hasCoupon,
        hasDbook,
        hasMeta,
        hotelId,
        isFiltered,
        layoutType,
        partnerId,
        price,
        requestId,
        roomTypes,
        searchId,
        currency,
        allRatesCount,
        ratesWithImageCount,
        stay,
        correlationId,
        dayviewCorrelationId,
      });
  }

  priceLoaded({
    allRatesCount,
    correlationId,
    cugDeal,
    currency,
    dBook,
    dayviewCorrelationId,
    hasCoupon,
    hasDbook,
    hasMeta,
    hotelId,
    isFiltered,
    layoutType,
    partnerId,
    price,
    ratesWithImageCount,
    requestId,
    roomTypes,
    searchId,
    stay,
  }: {
    cugDeal: boolean;
    hasCoupon: boolean;
    hasDbook: boolean;
    hasMeta?: boolean;
    dBook: boolean;
    price: number;
    hotelId: string;
    currency: string;
    searchId: string;
    partnerId: string;
    requestId: string;
    roomTypes?: string;
    layoutType?: string;
    isFiltered?: boolean;
    allRatesCount: number;
    ratesWithImageCount: number;
    stay: Stay;
    correlationId?: string;
    dayviewCorrelationId?: string;
  }) {
    const actionType = layoutType && PRICES_LOADED_TYPES[layoutType];
    if (actionType) {
      this.elementEventTracker.trackHotelsAction(
        actionType,
        buildAdditionalInfoPricesLoadedMessage({
          isCugDeal: cugDeal,
          hasCoupon,
          hasDbook,
          hasMeta,
          dBook,
          hotelId,
          partnerId,
          price,
          currency,
          searchId,
          layoutType,
          pageName: this.pageName,
          requestId,
          roomTypes,
          isFiltered,
          allRatesCount,
          ratesWithImageCount,
          stay,
          correlationId,
          dayviewCorrelationId,
        }),
      );
    }
  }

  pricesEmpty(hotelId: string) {
    this.statsd.increment('prices.empty', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });

    this.priceStatus({ hasRate: false, hotelId });
  }

  pricesFailure(hotelId: string) {
    this.statsd.increment('prices.failure', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });

    this.priceStatus({ hasRate: false, hotelId });
  }

  priceStatus({ hasRate, hotelId }: { hasRate: boolean; hotelId: string }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.HOTEL_PRICES_STATUS,
      buildAdditionalInfoHotelPriceStatusMessage({
        hasRate,
        hotelId,
      }),
    );
  }

  QRCodeGenerateSuccess() {
    this.statsd.increment('QRCode.success');
  }

  QRCodeGenerateFail() {
    this.statsd.increment('QRCode.failure');
  }

  dbookPriceSelected() {
    this.statsd.increment('dbookRateSelected');
  }

  hotelDetailsPriceLoaded() {
    this.statsd.increment('hotelDetailsPriceLoaded');
  }

  similarHotelsSuccess(tags = {}) {
    this.statsd.increment('similarHotels.success', 1, tags);
  }

  similarHotelsEmpty(tags = {}) {
    this.statsd.increment('similarHotels.empty', 1, tags);
  }

  similarHotelsFailure(tags = {}) {
    this.statsd.increment('similarHotels.failure', 1, tags);
  }

  bookingStartFailure() {
    this.statsd.increment('dbook.booking-start-failure', 1, {});
  }

  bookingFlowTransition({
    bookingId,
    errorCode,
    errorMessage,
    hotelId,
    messageState,
    priceChangedInfo,
    processState,
    resultStatus,
    searchCycleId,
    state,
    thirdPaymentEnabled,
  }: {
    processState: string;
    messageState?: string;
    state: string;
    hotelId: string;
    priceChangedInfo?: PriceChangedInfo;
    bookingId?: string;
    searchCycleId?: string;
    errorCode?: string;
    errorMessage?: string;
    resultStatus?: string;
    thirdPaymentEnabled?: boolean;
  }) {
    this.statsd.increment('dbook.booking-flow-transition', 1, { state });

    this.bookingProcessState({
      bookingId,
      hotelId,
      messageState,
      processState,
      priceChangedInfo,
      searchCycleId,
      errorCode,
      errorMessage,
      resultStatus,
      thirdPaymentEnabled,
    });
  }

  stathamBookingState(state: string, partnerId: string) {
    this.statsd.increment('dbook.statham-booking-state', 1, {
      state,
      partnerId,
    });
  }

  stathamBookingTiming(
    processMilliseconds: number,
    state: string,
    partnerId: string,
  ) {
    this.statsd.timing('dbook.statham-booking-state', processMilliseconds, {
      state,
      partnerId,
    });
  }

  preReservationAvailabilityCheck(
    state: string,
    {
      cugType,
      currency,
      expectedPrice,
      hotelId,
      isLoggedIn,
      market,
      prevTrackingId,
      priceConsistencyCheck,
      rateId,
      redirectId,
      roomId,
      searchCycleId,
      trackingId,
      type,
    }: {
      hotelId: string;
      isLoggedIn: boolean;
      market: string;
      roomId: string;
      rateId: string;
      searchCycleId: string;
      trackingId: string;
      prevTrackingId: string;
      priceConsistencyCheck: boolean;
      type: string | undefined;
      cugType?: Maybe<string>;
      redirectId: string;
      expectedPrice?: number;
      currency: string;
    },
  ) {
    this.statsd.increment('dbook.statham-availability-check', 1, {
      state,
      market,
      type,
      priceConsistencyCheck,
    });

    logger.event('pre_reservation_availability_check', {
      state,
      market,
      roomId,
      rateId,
      prevTrackingId,
      priceConsistencyCheck: `${priceConsistencyCheck}`,
      trackingId,
      cugType: cugType || undefined,
      isLoggedIn: `${isLoggedIn}`,
      searchCycleId,
    });

    if (!priceConsistencyCheck) {
      this.elementEventTracker.trackHotelsAction(
        ACTION_TYPE.PRE_CHECK_STATE,
        buildAdditionalInfoPreCheckState({
          cugType,
          hotelId,
          isLoggedIn,
          preCheckState: state,
          prevTrackingId,
          priceConsistencyCheck,
          rateId,
          redirectId,
          roomId,
          searchCycleId,
          trackingId,
          expectedPrice,
          currency,
        }),
      );
    }
  }

  finalScreenView(state: string, { reason }: { reason: string }) {
    this.statsd.increment('dbook.final-screen-view', 1, {
      state,
      reason,
    });
  }

  bookingPerformed({
    accountType,
    bookingId,
    bookingStatus,
    cug,
    currency,
    hotelId,
    isLoggedIn,
    market,
    partnerId,
    price,
    redirectId,
    resultStatus,
    roomRateId,
    searchId,
    stay,
    thirdPaymentEnabled,
  }: {
    accountType: string;
    isLoggedIn: boolean;
    bookingId: string;
    bookingStatus: string;
    currency: string;
    hotelId: string;
    partnerId: string;
    price?: number;
    roomRateId?: string;
    searchId: string;
    cug?: CugPrice;
    redirectId: string;
    stay: Stay;
    market: string;
    resultStatus?: string;
    thirdPaymentEnabled?: boolean;
  }) {
    const eventProps = {
      bookingId,
      hotelId,
      partnerId,
      roomRateId,
      searchId,
      redirectId,
      resultStatus: resultStatus && PAYMENT_RESULT_STATUS_MAPPING[resultStatus],
    };
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.BOOKING_PERFORMED,
      buildAdditionInfoBookingPerformed({
        ...eventProps,
        bookingStatus: BOOKING_STATUS_MAPPING[bookingStatus],
        currency,
        price,
        cug,
        stay,
      }),
    );
    logger.event('booking_performed', {
      ...eventProps,
      bookingStatus,
      accountType,
      cugType: cug && cug.type && cug.type,
      isLoggedIn: `${isLoggedIn}`,
      market,
      thirdPaymentEnabled: thirdPaymentEnabled ? 'true' : 'false',
    });
  }

  bookingProcessState({
    bookingId,
    errorCode,
    errorMessage,
    hotelId,
    messageState,
    policyChangedType,
    priceChangedInfo,
    processState,
    resultStatus,
    searchCycleId,
    thirdPaymentEnabled,
  }: {
    bookingId?: string;
    processState: string;
    messageState?: string;
    hotelId: string;
    priceChangedInfo?: PriceChangedInfo;
    policyChangedType?: string;
    searchCycleId?: string;
    errorCode?: string;
    resultStatus?: string;
    errorMessage?: string;
    thirdPaymentEnabled?: boolean;
  }) {
    const mappedResultStatus =
      resultStatus && PAYMENT_RESULT_STATUS_MAPPING[resultStatus];
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.BOOKING_PROCESS_STATE,
      buildAdditionInfoBookingProcessState({
        bookingId,
        hotelId,
        messageState,
        processState,
        priceChangedInfo,
        policyChangedType,
        searchCycleId,
        errorCode,
        resultStatus: mappedResultStatus,
      }),
    );

    let eventData = {
      bookingId,
      hotelId,
      messageState,
      processState,
      searchCycleId,
      paymentBackErrorCode: errorCode,
      paymentBackErrorMessage: errorMessage,
      paymentBackResultStatus: mappedResultStatus,
      policyChangedType,
      thirdPaymentEnabled: thirdPaymentEnabled ? 'true' : 'false',
    };
    if (priceChangedInfo) {
      eventData = { ...eventData, ...priceChangedInfo };
    }

    logger.event('booking_process_state', eventData);
  }

  paymentAuthChallengeIframeLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('paymentAuthChallenge.load', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  paymentAuthChallengeIframeInitialFrameLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('paymentAuthChallenge.initialFrameLoad', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  paymentAuthChallengeIframeSubsequentFrameLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('paymentAuthChallenge.subsequentFrameLoad', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  paymentAuthChallengeIframeCompleteChallenge({ partnerId }: PaymentProps) {
    this.statsd.increment('paymentAuthChallenge.completeChallenge', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  bookingSubmitError({ errorsFields }: OneOfTag) {
    this.statsd.increment('booking-submit-error', 1, {
      region: this.region,
      errors_fields: errorsFields,
    });
  }

  searchMapEntryClicked() {
    this.statsd.increment('searchMap-entry-clicked');
  }

  searchMapMarkerClicked() {
    this.statsd.increment('searchMap-marker-clicked');
  }

  searchMapCardSwiped() {
    this.statsd.increment('searchMap-card-swiped');
  }

  savePhoneNumberSeen() {
    this.statsd.increment('savePhoneNumber-savePhoneNumberSeen', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  savePhoneNumberChecked() {
    this.statsd.increment('savePhoneNumber-saveChecked', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  savePhoneNumberFailed() {
    this.statsd.increment('savePhoneNumber-savePhoneNumberFailed', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  saveThisCardSeen() {
    this.statsd.increment('saveRetrieveCard-saveThisCardSeen', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  saveCardChecked() {
    this.statsd.increment('saveRetrieveCard-cardChecked', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  storedCardSelected({ token }: { token: string }) {
    this.statsd.increment('saveRetrieveCard-storedCardSelected', 1, {
      card: token,
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  cardManageEdit() {
    this.statsd.increment('saveRetrieveCard-cardEditClicked', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  cardUpdateSave() {
    this.statsd.increment('saveRetrieveCard-cardUpdateClicked', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  createCardStatus({ statusCode }: CreateCardProps) {
    this.statsd.increment('saveRetrieveCard-create', 1, {
      status: statusCode,
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  retrieveCardStatus({ statusCode }: CreateCardProps) {
    this.statsd.increment('saveRetrieveCard-retrieve', 1, {
      status: statusCode,
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  updateCardStatus({ statusCode }: CreateCardProps) {
    this.statsd.increment('saveRetrieveCard-update', 1, {
      status: statusCode,
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  threeDSDeviceIframeComplete({ partnerId }: PaymentProps) {
    this.statsd.increment('threeDSDevice.complete', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  threeDSDeviceIframeLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('threeDSDevice.load', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  threeDSDeviceIframeInitialFrameLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('threeDSDevice.initialFrameLoad', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  threeDSDeviceIframeSubsequentFrameLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('threeDSDevice.subsequentFrameLoad', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  threeDSStart({ partnerId, type }: PaymentProps) {
    this.statsd.increment('threeDS.start', 1, {
      partnerId,
      type,
    });
  }

  threeDSSuccess({ partnerId, type }: PaymentProps) {
    this.statsd.increment('threeDS.success', 1, {
      partnerId,
      type,
    });
  }

  threeDS2OfSCStart({ currency, market, partnerId }: ThreeDS2PaymentProps) {
    this.statsd.increment('threeDSTwoOfSC.start', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      partnerId,
      market,
      currency,
    });
  }

  threeDS2OfSCSuccess({ currency, market, partnerId }: ThreeDS2PaymentProps) {
    this.statsd.increment('threeDSTwoOfSC.success', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      partnerId,
      market,
      currency,
    });
  }

  threeDS2OfSCFailed({ currency, market, partnerId }: ThreeDS2PaymentProps) {
    this.statsd.increment('threeDSTwoOfSC.failed', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      partnerId,
      market,
      currency,
    });
  }

  babysharkSuccess({
    currency,
    env,
    isSupported,
    issuercc,
    market,
    partnerId,
  }: BabysharkPrecheckProps) {
    this.statsd.increment('babyshark.preauthenticate-success', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      partnerId,
      market,
      currency,
      env,
      issuercc,
      isSupported,
    });
  }

  babysharkFailed({
    currency,
    env,
    market,
    partnerId,
  }: BabysharkPrecheckProps) {
    this.statsd.increment('babyshark.preauthenticate-failed', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      partnerId,
      market,
      currency,
      env,
    });
  }

  unifiedPaymentStatus({
    action,
    bookingId,
    market,
    partnerId,
    retry,
    status,
    url,
  }: {
    action: string;
    market: string;
    partnerId: string;
    status: string;
    bookingId: string;
    url: string;
    retry: boolean;
  }) {
    this.statsd.increment('nairobi.unified.payment', 1, {
      device: getDeviceType(this.deviceInfo),
      action,
      status,
      market,
      partnerId,
    });

    logger.event('nairobi.unified.payment', {
      action,
      bookingId,
      status,
      market,
      partnerId,
      retry: `${retry}`,
      url,
    });

    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.UNIFIED_PAYMENT_INTEGRATION,
      buildAdditionalInfoUnifiedPaymentPlatformMessage({
        action,
        bookingId,
        partnerId,
        status: status === '200' ? 'SUCCESSFUL' : 'ERROR',
        retry,
      }),
    );
  }

  componentLoadFailure({
    componentName,
    market,
  }: {
    market: string;
    componentName: string;
  }) {
    this.statsd.increment('componentLoad.failure', 1, {
      pageName: this.pageName,
      market,
      componentName,
      isRobot: this.deviceInfo.isRobot,
      device: getDeviceType(this.deviceInfo),
    });
  }

  searchFilterUsed({
    filterArea,
    filterId,
    filterIdByClient,
    filterOptions,
    filterValue,
    originalSearchStartFinishCycleId,
    searchStartFinishCycleId,
  }: {
    filterId: string;
    filterValue: string[];
    filterOptions: Array<{
      id: string;
      count: number;
      enabled: boolean;
      label: string;
    }>;
    originalSearchStartFinishCycleId: string;
    searchStartFinishCycleId: string;
    filterIdByClient?: string;
    filterArea?: string;
  }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.DAY_VIEW_FILTERS_USED,
      buildDayViewFiltersUsedMessage({
        filterId,
        filterIdByClient,
        filterValue,
        filterOptions,
        originalSearchStartFinishCycleId,
        searchStartFinishCycleId,
        filterArea,
      }),
    );
  }

  searchSortUsed({
    entityId,
    originalSearchStartFinishCycleId,
    searchStartFinishCycleId,
    selectedValue,
    sortClickArea = null,
  }: {
    selectedValue: string;
    entityId: number;
    searchStartFinishCycleId: string;
    originalSearchStartFinishCycleId: string;
    sortClickArea?: string | null;
  }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.DAY_VIEW_SORT_USED,
      buildDayViewSortUsedMessage({
        selectedValue,
        entityId,
        searchStartFinishCycleId,
        originalSearchStartFinishCycleId,
        sortClickArea,
      }),
    );
  }

  searchPaginationClick({
    originalSearchStartFinishCycleId,
    pageIndex,
    searchStartFinishCycleId,
  }: {
    pageIndex: number;
    searchStartFinishCycleId: string;
    originalSearchStartFinishCycleId: string;
  }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.DAY_VIEW_PAGINATION_CLICK,
      {
        index: {
          index: pageIndex,
          search_start_finish_cycle_id: searchStartFinishCycleId,
          original_search_start_finish_cycle_id:
            originalSearchStartFinishCycleId,
        },
      },
    );
  }

  errorBoundaryButtonClick() {
    this.statsd.increment('errorBoundary.goBack.clicked', 1, {
      pageName: this.pageName,
    });
  }

  dealsHotelCardClicked({
    couponType,
    cug,
    currency,
    funnelType,
    goToDVEnabled,
    greatPricePercentage,
    groupKey,
    hasCoupon,
    id,
    isCoupon,
    isGoToHD,
    isMainPrice,
    isMapPanelHotel,
    isSavedHotel,
    mainPriceArea,
    market,
    partnerId,
    price,
    stars,
  }: {
    couponType: string;
    cug?: CugPrice;
    funnelType: string;
    goToDVEnabled: boolean;
    hasCoupon: boolean;
    isCoupon: boolean;
    isGoToHD: boolean;
    isMainPrice: boolean;
    isMapPanelHotel: boolean;
    mainPriceArea: string;
    partnerId: string;
    stars: number;
    groupKey: string;
    price: number;
    id: string;
    currency: string;
    market: string;
    greatPricePercentage: number;
    isSavedHotel: boolean;
  }) {
    this.statsd.increment('dealsHotelCardClicked', 1, {
      market,
      groupKey,
      trafficSource: this.trafficSource,
    });

    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.DAY_VIEW_HOTEL_CARD_CLICKED,
      buildDayViewHotelCardClickedMessage({
        price_type: cug ? 'FSS' : funnelType,
        partnerId,
        isMainPrice,
        isGoToHD,
        mainPriceArea,
        stars,
        hasCoupon,
        isCoupon,
        goToDVEnabled,
        couponType,
        isViewedHotel: false,
        cug,
        groupKey,
        price,
        hotelId: id,
        currency,
        greatPricePercentage,
        isMapPanelHotel,
        isSavedHotel,
      }),
    );
  }

  dealsNavButtonClicked({
    cityId,
    clickArea,
    groupKey,
    isBestDeal,
    isCityChips = false,
    market,
  }: {
    cityId: string;
    groupKey: string;
    clickArea: string;
    isBestDeal: boolean;
    isCityChips?: boolean;
    market: string;
  }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.DEALS_NAV_CLICKED,
      buildAdditionalInfoDealsCitiesFilterClick({
        cityId,
        groupKey,
        clickArea,
        isBestDeal,
      }),
    );
    if (!isCityChips) {
      this.statsd.increment('dealsNavRedirect', 1, {
        groupKey,
        market,
        trafficSource: this.trafficSource,
      });
    }
  }
}

export default ApplicationMetrics;
