import React from 'react';
import type { ComponentType } from 'react';

import displayName from './display-name';

import type { Logger } from '../types/logger';

type ContextProps = {
  logger: Logger;
};

// @ts-expect-error missing properties
const LoggingContext = React.createContext<Logger>({});

const withLogging = <P extends ContextProps>(Component: ComponentType<P>) => {
  const component = (props: Omit<P, 'logger'>) => (
    <LoggingContext.Consumer>
      {(context: Logger) => (
        // @ts-expect-error we expect that logger can be overwritten in the unit tests
        <Component logger={context} {...(props as P)} />
      )}
    </LoggingContext.Consumer>
  );

  component.displayName = displayName(Component);

  return component;
};

export default LoggingContext;
export { withLogging };
