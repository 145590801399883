const MAX_RETRIES = 4;
const RETRY_INTERVAL = 250;

const retry = (
  fn: () => Promise<any>,
  retries = MAX_RETRIES,
  interval = RETRY_INTERVAL,
): Promise<any> =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (retries === 0) {
          reject(error);
        } else {
          setTimeout(
            () => retry(fn, retries - 1, interval * 2).then(resolve, reject),
            interval,
          );
        }
      });
  });

export default retry;
