const DbookFunnelStates = {
  LOADING: 'LOADING',
  CHECKOUT: 'CHECKOUT',
  SUBMITTING: 'SUBMITTING',
  BOOKING: 'BOOKING',
  CONFIRMED: 'CONFIRMED',
  ACCEPTED: 'ACCEPTED',
  UNCONFIRMED: 'UNCONFIRMED',
  THREE_DS: 'THREE_DS',
  DEVICE_COLLECT: 'DEVICE_COLLECT',
  PAYMENT_SUBMITTED: 'PAYMENT_SUBMITTED',
  BOOKING_CREATING: 'BOOKING_CREATING',
  PAYMENT_SUCCEEDED: 'PAYMENT_SUCCEEDED',
  PAYMENT_BACK_EXCEPTION: 'PAYMENT_BACK_EXCEPTION',
  FAILED: 'FAILED',
};

const MessageStates = {
  CHECKOUT_ERROR: 'CHECKOUT_ERROR',
  PRICE_HAS_CHANGED: 'PRICE_HAS_CHANGED',
  PRICE_HAS_CHANGED_NO_RATE: 'PRICE_HAS_CHANGED_NO_RATE',
  ROOM_UNAVAILABLE: 'ROOM_UNAVAILABLE',
  POLICY_HAS_CHANGED: 'POLICY_HAS_CHANGED',
  CREATE_BOOKING_FAILED: 'CREATE_BOOKING_FAILED',
  PAYMENT_BACK_FAILED: 'PAYMENT_BACK_FAILED',
  BOOKING_UNKNOWN_STATUS: 'BOOKING_UNKNOWN_STATUS',
};

export type DbookFunnelStatesValues =
  (typeof DbookFunnelStates)[keyof typeof DbookFunnelStates];
export type MessageStatesValues =
  (typeof MessageStates)[keyof typeof MessageStates];

const BookingFailedStates = [
  MessageStates.PAYMENT_BACK_FAILED,
  MessageStates.BOOKING_UNKNOWN_STATUS,
  MessageStates.CREATE_BOOKING_FAILED,
];

const isBookingFailedState = (state?: string) =>
  BookingFailedStates.indexOf(state || '') !== -1;

const FinalBookingStates = [
  DbookFunnelStates.UNCONFIRMED,
  DbookFunnelStates.CONFIRMED,
  DbookFunnelStates.ACCEPTED,
  DbookFunnelStates.FAILED,
];

const isFinalState = (state?: string) =>
  FinalBookingStates.indexOf(state || '') !== -1;

export {
  DbookFunnelStates,
  MessageStates,
  FinalBookingStates,
  isFinalState,
  isBookingFailedState,
};
