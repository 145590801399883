import React from 'react';
import type { ComponentType } from 'react';

import displayName from '../../components/display-name';

import type { Metrics } from '../types';

interface ContextProps {
  metrics: Metrics;
}

// @ts-expect-error missing properties
const MetricsContext = React.createContext<Metrics>({});

const withMetrics = <P extends ContextProps>(Component: ComponentType<P>) => {
  const component = (props: Omit<P, 'metrics'>) => (
    <MetricsContext.Consumer>
      {(metrics: Metrics) => (
        // @ts-expect-error we expect that metrics can be overwritten in the unit tests
        <Component metrics={metrics} {...(props as P)} />
      )}
    </MetricsContext.Consumer>
  );

  component.displayName = displayName(Component);

  return component;
};

export default MetricsContext;
export { withMetrics };
